import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKListPicker,
} from "deskera-ui-library";
import { Component, Fragment } from "react";
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import ic_scanner from "../../assets/mobile/ic_scanner.png";
import { MOBILE_APP_ACTIONS } from "../../constants/Constant";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import UserManager from "../../managers/UserManager";
import SideBarService from "../../services/sidebar";
import {
  isMobileAppWebView,
  isViewportLarge,
} from "../../utility/GetViewportSize";
import Utility from "../../utility/Utility";
import AppTopMenuProfileOption from "./AppTopMenuProfileOption";

import { connect } from "react-redux";

import ic_essential from "../../assets/pricingSection/essential.svg";
import ic_professional from "../../assets/pricingSection/professional.svg";
import ic_startup from "../../assets/pricingSection/startup.svg";

import ApiConstants from "../../constants/ApiConstants";
import {
  AUTHORIZATION_STATUS,
  PLANS,
  PRODUCTS,
  USER_ROLE,
} from "../../constants/Enum";
import AppManager from "../../managers/AppManager";
import { COMMON_EVENTS, commonCustomEvent } from "../../services/event";
import IAM from "../../services/iam";
import SubscriptionAlert from "../subscription/SubscriptionAlert";
// import AppManager from "../../managers/AppManager";
import { fetchApplications } from "../../redux/slices/applicationSlice";
/*
- isOrgAvailable
*/
import { useNavigate } from "react-router-dom";

function withNavigation(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };
}

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needTutorialPopup: false,
      needProfileOptionsSection: false,
      isDesktop: isViewportLarge(),
      subscriptionData: null,
      showPopup: false,
      isLoading: false,
      showMainFourPopup: false,
      app: props.app,
      navigate: props.navigate

      // appNameHolder: ''
    };
  }

  setAppName = (e) => {
    console.log("Event:", e);
    this.setState({
      appNameHolder: e.target.value,
    });
  };

  handleButtonClick = () => {
    this.setState({ showPopup: true });
  };

  handleClosePopup = () => {
    this.setState({ showPopup: false });
  };
  handleMainFourButtonClick = () => {
    this.setState({ showMainFourPopup: true });
  };
  handleMainFourClosePopup = () => {
    this.setState({ showMainFourPopup: false });
  };
  componentDidMount() {
    window.addEventListener("resize", this.windowSizeUpdated);

    if (!isMobileAppWebView()) {
      this.onWindowFocussed();
      AppManager.handleWindowFocusListeners(this.onWindowFocussed, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowSizeUpdated);
    if (!isMobileAppWebView()) {
      AppManager.handleWindowFocusListeners(this.onWindowFocussed, false);
    }
  }

  windowSizeUpdated = () => {
    this.setState({
      isDesktop: isViewportLarge(),
    });
  };

  render() {
    return !AppManager.isMobileAppURL() ? this.renderMenu() : null;
  }

  renderMenu() {
    const { isDesktop } = this.state;

    const onSelectOption = (option) => {
      console.log(option);
      if (option === "Table") {
        console.log("App in this", this.state.app);
        this.props.navigate(`/create-table/${this.state.app._id}`);
      } else if (option === "Report") {
        console.log("Report clicked");
      } else if (option === "Page") {
        console.log("Page clicked");
      } else if (option === "Workflow") {
        console.log("Workflow clicked");
      }
      this.handleMainFourClosePopup();
    };
    return (
      <Fragment>
        <div
          className="parent-width row justify-content-between p-h-r p-v-s border-b-gray shadow-s z-index-3 flex-shrink-0"
          style={{
            backgroundColor: isMobileAppWebView() ? "#2C1F47" : "white",
          }}
        >
          <div
            className="dk-sidebar-toggle p-v-s expandable-button cursor-hand z-index-4 column justify-content-center display-only-mobile"
            onClick={() => SideBarService.toggleSideBar()}
          >
            <div className="row">
              <DKIcon
                src={
                  isMobileAppWebView() ? DKIcons.white.ic_menu : DKIcons.ic_menu
                }
                className={`ic-s shadow-s-2`}
              />
              {isMobileAppWebView() && this.getTenantNameView()}
            </div>
          </div>
          <div className="row width-auto">{this.getNoCardView()}</div>
          <div className="row width-auto">
            {isMobileAppWebView() && this.getMobileAppActions()}
            {/* {!isMobileAppWebView() && (
                            <div className="position-relative">
                                <DKButton
                                    icon={DKIcons.white.ic_add}
                                    title={isDesktop ? "Create a New Table" : ""}
                                    className={`bg-button text-white ml-m ${
                                        isDesktop ? "" : "circle"
                                    }`}
                                    onClick={() => {
                                        console.log("App in this", this.state.app)
                                        this.props.navigate(`/create-table/${this.state.app._id}`)
                                    }}
                                />


                            </div>
                        )} */}

            {!isMobileAppWebView() && (
              <div className="position-relative">
                <div className= "flex flex-row">

                  <DKButton
                      title={ "Go to admin dashboard" }
                      className={`border-m bg-slate-100 `}
                      onClick={()=>{this.state.navigate("/")}}
                  />
                  <DKButton
                      icon={DKIcons.white.ic_add}
                      title={isDesktop ? "New" : ""}
                      className={`bg-button text-white ml-m ${
                          isDesktop ? "" : "circle"
                      }`}
                      onClick={this.handleMainFourButtonClick}
                  />
                </div>

                {/* {this.state.needAddNewList && this.getAddNewList(list)} */}
                {this.state.showMainFourPopup && (
                  <DKListPicker
                    data={["Table", "Report", "Page", "Workflow"]}
                    icons={[
                      DKIcons.ic_dashboard,
                      DKIcons.ic_reports,
                      DKIcons.ic_document,
                      DKIcons.ic_reload,
                    ]}
                    onSelect={(index, value) => onSelectOption(value)}
                    style={{
                      position: "absolute",
                      top: "30px",
                      width: "120px",
                    }} // Adjust positioning as needed
                    onClose={this.state.handleMainFourClosePopup}
                  />
                )}
              </div>
            )}

            {!isMobileAppWebView() && (
              <div className="position-relative ml-m">
                {this.props.isNewNotification && (
                  <div
                    className="position-absolute p-xs bg-red border-radius-m"
                    style={{ right: 0 }}
                  />
                )}
              </div>
            )}
            {!isMobileAppWebView() && this.getProfileSection()}
          </div>
        </div>
        {this.getSubscriptionAlert()}
      </Fragment>
    );
  }

  getProfileSection() {
    // const {usersInfo} = this.props;
    const loggedInUserInfo = this.props?.usersInfo?.content?.find(
      (user) => user.id === UserManager.getUserID()
    );

    return (
      <div className=" position-relative ml-m">
        <div
          className="cursor-hand"
          onClick={() => {
            this.setState({ needProfileOptionsSection: true });
          }}
          style={{ opacity: 0.6 }}
        >
          <DKIcon src={ic_profile_pic} className="circle ic-r-3" />
        </div>

        {this.state.needProfileOptionsSection &&
          this.getProfileOptionsSection(loggedInUserInfo)}
      </div>
    );
  }

  getProfileOptionsSection(loggedInUserInfo) {
    return (
      <AppTopMenuProfileOption
        username={loggedInUserInfo?.firstName || ""}
        onClose={() => {
          setTimeout(() => {
            this.setState({ needProfileOptionsSection: false });
          }, 10);
        }}
      />
    );
  }

  onWindowFocussed = (e) => {
    if (isMobileAppWebView()) {
      return;
    }

    if (document.hidden || !AppManager.needSubscriptionApiCall) return;

    commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
      status: AUTHORIZATION_STATUS.PENDING,
    });

    IAM.checkIfUserSubscribed()
      .then(
        () => {
          /* Calling once on focus Only */
          AppManager.needSubscriptionApiCall = false;

          /* For triggering Top menu Rerender in case alert not opened,
                              If Subscription alert already opened,
                              updated message will get showcased through custom event  */
          if (!this.state.subscriptionData?.needAlert) {
            this.setState({
              subscriptionData: null,
            });
          }

          commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
            status: AUTHORIZATION_STATUS.AUTHORIZED,
          });

          /* Refreshing user role info, for checking updated product access on subscription */
          this.props
            .fetchUserInfo({ params: { id: UserManager.getUserID() } })
            .then((res) => this.checkProductAccessForUser(res?.payload));
        },
        () => {
          /* Need to Show Subscription alert */
          this.setState({
            subscriptionData: {
              customMessage: "",
              needAlert: true,
            },
          });

          AppManager.needSubscriptionApiCall = true;

          commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
            status: AUTHORIZATION_STATUS.DENIED,
          });
        }
      )
      .catch((err) => {
        AppManager.needSubscriptionApiCall = true;
        commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
          status: AUTHORIZATION_STATUS.DENIED,
        });
      });
  };

  checkProductAccessForUser = (usersInfo = this.props.usersInfo) => {
    /* Checking if user has access to CRM+ product */
    const loggedInUserInfo = usersInfo?.content?.find(
      (user) => user.id === UserManager.getUserID()
    );

    const isOrgOwner =
      loggedInUserInfo?.roleShortInfo?.find(
        (role) => PRODUCTS.ORGANISATION === role.appName?.toLowerCase()
      )?.shortCode === USER_ROLE.OWNER.toLowerCase();

    if (
      isOrgOwner ||
      Utility.isEmptyObject(loggedInUserInfo) ||
      AppManager.needSubscriptionApiCall
    )
      return;

    if (!UserManager.hasProductAccess(loggedInUserInfo)) {
      this.props.history.push(PAGE_ROUTES.NO_ACCESS);
    }
  };

  getSubscriptionAlert() {
    return this.state.subscriptionData?.needAlert ? (
      <SubscriptionAlert
        customMessage={this.state.subscriptionData.customMessage || ""}
        onClose={() => {
          this.setState({
            subscriptionData: null,
          });
        }}
      />
    ) : null;
  }

  //////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////

  getPlanIcon(planName = "") {
    let iconSrc = null;
    switch (planName.toLowerCase()) {
      case PLANS.STARTUP:
        iconSrc = ic_startup;
        break;
      case PLANS.ESSENTIAL:
        iconSrc = ic_essential;
        break;
      case PLANS.PROFESSIONAL:
        iconSrc = ic_professional;
        break;
      default:
    }

    return iconSrc;
  }

  getPlanBadgeStatus(isPlanActive) {
    return isPlanActive ? "Active" : "Expired";
  }

  getNoCardView() {
    // let helloKeywords = [
    //     "Hi",
    //     "Hello",
    //     "Hey",
    //     "Hola",
    //     "Bonjour",
    //     "Salve",
    //     "Nǐn hǎo",
    //     "Namaste",
    // ];
    // let hey = helloKeywords[Math.floor(Math.random() * helloKeywords.length)];
    // let message = `👋 ${hey}! We will need your payment details for uninterrupted access 🙂`;

    //${hey}!
    // let message = `<span style="font-size: 16px">👋</span> Welcome <b>${UserManager.getUserName()}</b>`;

    const userPlan = {
      name: "",
      icon: "",
      inTrial: UserManager.isTrialPlanActive(),
      isActive: UserManager.isPlanActive(),
      trialDays: 0,
    };

    if (userPlan.inTrial) {
      userPlan.trialDays = UserManager.getTrialDays(true);
    } else if (userPlan.isActive) {
      userPlan.name = UserManager.getUserPlanName();
      userPlan.icon = this.getPlanIcon(userPlan.name);
    }
    const loggedInUserInfo = this.props?.usersInfo?.content?.find(
      (user) => user.id === UserManager.getUserID()
    );
    return (
      <div className=" display-only-web border-ss p-h-s border-radius-m">
        <div
          className="row align-items-center border-box"
          style={{ paddingBottom: 2 }}
        >
          {loggedInUserInfo && (
            <>
              <DKLabel text={"👋"} className="fs-l" />
              <DKLabel text={" Welcome "} />
              <DKLabel
                text={`${loggedInUserInfo?.firstName || ""} ${
                  loggedInUserInfo?.lastName || ""
                }`}
                className="fw-b"
              />
            </>
          )}
          {userPlan.inTrial ? (
            <Fragment>
              <DKLabel
                text={`, Your trial will end ${
                  userPlan.trialDays ? `in ${userPlan.trialDays} days` : `today`
                }, `}
                className="fw-m text-red"
              />
              <DKButton
                title={`subscribe now`}
                className="fw-m text-blue text-underline"
                style={{ padding: "0 2px" }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + "billing");
                }}
              />
            </Fragment>
          ) : userPlan.name ? (
            <Fragment>
              <DKLabel text=", you are on " />
              <DKButton
                title={`${userPlan.name} Plan`}
                className="fw-m text-blue text-underline"
                style={{ padding: "0 2px" }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + "billing");
                }}
              />
              <DKIcon src={userPlan.icon} className="ic-s-2 pl-xs" />
              <DKLabel
                text={this.getPlanBadgeStatus(userPlan.isActive)}
                className={`${
                  userPlan.isActive
                    ? "bg-chip-green border-green text-green"
                    : "bg-chip-red border-red text-red"
                } fw-m border-radius-r ml-s p-h-s`}
              />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getTenantNameView() {
    return (
      <DKLabel
        className="fw-m fs-l p-h-m text-white"
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "160px",
        }}
        text={UserManager.getUserTenantName()}
      />
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getMobileAppActions() {
    return (
      <div className="row position-relative ml-m">
        <DKButton
          icon={DKIcons.ic_home}
          className={"mr-m circle bg-white"}
          onClick={() =>
            RouteManager.navigateToPage(PAGE_ROUTES.MOBILE_APP_LANDING)
          }
        />
        <DKButton
          icon={ic_scanner}
          className={"circle bg-white"}
          onClick={() =>
            Utility.postMobileAppActions(MOBILE_APP_ACTIONS.SCANNER)
          }
        />
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({
//   applications: state.applications.applications,
//   isLoading: state.applications.isLoading,
//   error: state.applications.error,
//   searchTerm: state.applications.searchTerm,
// });
const mapStateToProps = (state) => ({
  applications: state.applications.applications,
  isLoading: state.applications.isLoading,
  error: state.applications.error,
  searchTerm: state.applications.searchTerm,
  usersInfo: state.tenant.usersInfo, // Ensure this line is present
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: () => dispatch(fetchApplications()), // Add this line
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavigation(TopMenu));
