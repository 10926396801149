// @ts-ignore
import {format, parse} from 'date-fns';

export class DateUtil {

    static orgDateFormat = null;
    static setOrgDateFormat = (dateFormat: DATE_FORMATS | null) => {
        // @ts-ignore
        this.orgDateFormat = dateFormat;
    }
    static getOrgDateFormat = () => {
        return this.orgDateFormat;
    }

    static getDateString(date: Date): string {
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }

    /**
     * @param dateString - date string in the format specified for date-fns
     * @param format - format of the date string from DATE_FORMATS
     * @returns - date object
     */
    static getDateFromString(dateString: string, format: string = DateUtil.getOrgDateFormat() || DATE_FORMATS['DD-MM-YYYY']): Date {
        return parse(dateString, convertCRMDateFormatToUILibraryFormat(format), new Date());
    }


    /**
     * Convert date string from one format to another
     * @param dateStr Date string to be formatted
     * @param fromFormat Any one of the book's date formats
     * @param toFormat (Optional) Any one of the book's date formats
     * @returns formatted date string as per tenant's date format, or, a provided book's date format
     */
    static getFormattedDateString(dateStr: string, fromFormat: DATE_FORMATS, toFormat: DATE_FORMATS = DATE_FORMATS['DD-MM-YYYY']): string {
        try {
            return format(this.getDateFromString(dateStr, fromFormat), this.getDateFormatForDateFns(toFormat));
        } catch (e: any) {
            console.error('Error formatting Date: ', dateStr);
            return '';
        }
    }

    /**
     *
     * @param format - format of the date string from DATE_FORMATS
     * @returns - date string in the format specified for date-fns
     */
    static getDateFormatForDateFns(format: string): string {
        // @ts-ignore
        return DATE_FUNCTION_DATE_FORMATS[format];
    }

    static getFullDateString(date: Date): string {
        let day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
        let month = (date.getMonth() + 1) >= 10 ? (date.getMonth() + 1) : `0${date.getMonth() + 1}`;
        return date.getFullYear() + "-" + month + "-" + day;
    }

    static getTimeZoneOffsetValue() {
        var offset = new Date().getTimezoneOffset();
        var o = Math.abs(offset);
        return (
            (offset < 0 ? '+' : '-') +
            ('00' + Math.floor(o / 60)).slice(-2) +
            ':' +
            ('00' + (o % 60)).slice(-2)
        );
    }

    static getDateFromStr(dateStr: string, fromFormat: DATE_FORMATS): Date {
        return parse(
            dateStr,
            convertCRMDateFormatToUILibraryFormat(fromFormat),
            new Date()
        );
    }

    static getDateStrFromDate(date: Date, toFormat?: DATE_FORMATS): string {
        try {
            return format(
                new Date(date),
                // @ts-ignore
                convertCRMDateFormatToUILibraryFormat(toFormat)
            );
        } catch (e: any) {
            console.error('Error formatting Date: ', date);
            return '';
        }
    }

    static formatDate(date: Date, dateFormat: DATE_FORMATS) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        switch (dateFormat) {
            case DATE_FORMATS.DD_MM_YYYY:
                return [day, month, year].join('-');
            case DATE_FORMATS['DD-MM-YYYY']:
                return [day, month, year].join('-');
            case DATE_FORMATS['YYYY-MM-DD']:
                return [year, month, day].join('-');
            default:
                return [day, month, year].join('-');
        }
    }

    static getDateWithCurrentTime(date: Date | string) {
        let dateValue = new Date(date);
        const todayDate = new Date();

        if (Boolean(dateValue.getTime())) {
            /* If valid date, then update time to current time */
            dateValue.setHours(todayDate.getHours());
            dateValue.setMinutes(todayDate.getMinutes());
        } else {
            dateValue = todayDate;
        }

        return dateValue;
    }
}

export enum DATE_FORMATS {
    'D MMM YYYY' = 'D MMM YYYY',
    'DD-MM-YYYY' = 'DD-MM-YYYY',
    'DD-MMM-YYYY' = 'DD-MMM-YYYY',
    'YYYY-MM-DD' = 'YYYY-MM-DD',
    'MM-DD-YYYY' = 'MM-DD-YYYY',
    'MM/DD/YYYY' = 'MM/DD/YYYY',
    'DD/MM/YYYY' = 'DD/MM/YYYY',
    'DD_MM_YYYY' = 'dd-mm-yyyy',
    'DATE_FORMAT_MEDIUM' = 'DD-MM-YYYY HH:mm:ss',
    'ISO-FORMAT' = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z"
}

/**
 * @description - this is the mapping of the date format to the date-fns format
 */
export const DATE_FUNCTION_DATE_FORMATS = {
    [DATE_FORMATS['DD-MM-YYYY']]: 'dd-MM-yyyy',
    [DATE_FORMATS['DD-MMM-YYYY']]: 'dd-MMM-yyyy',
    [DATE_FORMATS['MM-DD-YYYY']]: 'MM-dd-yyyy',
    [DATE_FORMATS['YYYY-MM-DD']]: 'yyyy-MM-dd',
    [DATE_FORMATS['DD/MM/YYYY']]: 'dd/MM/yyyy',
    [DATE_FORMATS['MM/DD/YYYY']]: 'MM/dd/yyyy',
    [DATE_FORMATS['D MMM YYYY']]: 'd MMM yyyy',
    [DATE_FORMATS['ISO-FORMAT']]: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
}
export const convertCRMDateFormatToUILibraryFormat = (
    dateFormat: string
): string => {
    const formatsMap: { [key: string]: string } = {
        [DATE_FORMATS['DD-MM-YYYY']]: 'dd-MM-yyyy',
        [DATE_FORMATS['MM-DD-YYYY']]: 'MM-dd-yyyy',
        [DATE_FORMATS['YYYY-MM-DD']]: 'yyyy-MM-dd',
        [DATE_FORMATS['DD/MM/YYYY']]: 'dd/MM/yyyy',
        [DATE_FORMATS['MM/DD/YYYY']]: 'MM/dd/yyyy',
        [DATE_FORMATS['DD-MMM-YYYY']]: 'dd-MMM-yyyy',
        [DATE_FORMATS['D MMM YYYY']]: 'd MMM yyyy'
    };

    return formatsMap[dateFormat?.toUpperCase()] || formatsMap[DATE_FORMATS['DD-MM-YYYY']];
};