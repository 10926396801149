import React, { useState } from 'react';
import API from '../api';
import { useNavigate } from 'react-router-dom';
import ApiConstants from '../../constants/ApiConstants';

const Home = () => {
    const [prompt, setPrompt] = useState('');
    const [appId, setAppId] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleCreateApp = async () => {
        if (!prompt) return;
        setLoading(true);
        try {
            const response = await API.post('app-builder/create-app', { prompt });
            setAppId(response.data.appId);
        } catch (error) {
            console.error('Error creating app:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', textAlign: 'center', padding: '2rem' }}>
            <div>
                <h1 style={{ fontSize: '2rem' }}>What can I help you design, develop, and run today?</h1>
                <p style={{ margin: '1rem 0' }}>
                    We are helping people build software that helps you design, develop, and run any business software you need.
                    <br/>Making your business operations smoother and more efficient.
                </p>
                <div style={{ margin: '2rem 0' }}>
                    <input
                        type="text"
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="Ask Deskera Builder..."
                        style={{
                            width: '70%',
                            padding: '1rem',
                            fontSize: '1rem',
                            borderRadius: '5px',
                            border: '1px solid #ccc',
                        }}
                    />
                    <button
                        onClick={handleCreateApp}
                        disabled={!prompt || loading}
                        style={{
                            padding: '1rem',
                            fontSize: '1rem',
                            marginLeft: '1rem',
                            borderRadius: '5px',
                            backgroundColor: !prompt || loading ? '#ccc' : '#007bff',
                            color: 'white',
                            border: 'none',
                            cursor: !prompt || loading ? 'not-allowed' : 'pointer',
                        }}
                    >
                        {loading ? 'Loading...' : 'Submit'}
                    </button>
                </div>
                <div style={{ margin: '0.8rem 0' }}>
                    {['Create My Restaurant POS', 'Build My Real Estate CRM', 'Develop My Boutique Inventory'].map((suggestion, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                setPrompt(suggestion);
                                handleCreateApp();
                            }}
                            style={{
                                padding: '0.5rem 1rem',
                                fontSize: '0.8rem',
                                margin: '0.5rem',
                                borderRadius: '25px',
                                backgroundColor: '#f0f0f0',
                                border: '1px solid #ccc',
                                cursor: 'pointer',
                            }}
                        >
                            {suggestion}
                        </button>
                    ))}
                </div>
                {appId && (
                    <div style={{ marginTop: '2rem' }}>
                        <button
                            onClick={() => navigate(`/app/${appId}`)}
                            style={{
                                padding: '1rem',
                                fontSize: '1rem',
                                borderRadius: '5px',
                                backgroundColor: '#28a745',
                                color: 'white',
                                border: 'none',
                            }}
                        >
                            Go to Created App
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Home;