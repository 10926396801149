import AppManager from "../managers/AppManager";
import ApiConstants from "../constants/ApiConstants";
import UserManager from "./UserManager";
import Utility, {parseJWTToken} from "../utility/Utility";
import {store} from "../redux/store";
import {fetchTenantDetails, fetchTenantsInfo, fetchUserInfo} from "../redux/slices/tenantSlice";
import User from "../services/user";
import {showAddNewTenantPopup} from "../components/common/AddNewTenant";

export default class ApiManager {

    static audioService;
    static wsConnection;

    static tokenReceived(accessToken, emailVerified) {
        let token = parseJWTToken(accessToken);
        ApiConstants.ACCESS_TOKEN = accessToken;
        UserManager.setUserDetails({...User.getUserObjectFromToken(token), emailVerified});

        store.dispatch(fetchTenantDetails()).then((res) => {
            const response = res?.payload;
            if (!Utility.isEmptyObject(response) && !response['isDemoOrg'] && !response['orgSetupCompleted']) {
                showAddNewTenantPopup({tenantEdit: response}, () => {
                }, () => {
                });
            }
            UserManager.setUserTenantName(response?.name);
        });
        store.dispatch(fetchTenantsInfo(UserManager.getUserTenantID()));
        store.dispatch(fetchUserInfo({params: {id: UserManager.getUserID()}}));
        AppManager.userLoggedIn();
    }
}