import React, {useEffect} from 'react';
import {removeLoader, showAlert, showLoader} from 'deskera-ui-library';
import {EmailService} from '../services/common/email';

export default function EmailVerification(props) {
    /** state goes here */
    /** business logic goes here */
    const verifyEmail = (id) => {
        let title = 'Email verified!';
        let message = 'Your email address has been successfully verified. You can close the window and continue using the app now.';
        showLoader('Verifying your email address...');
        EmailService.verifyEmail({id})
            .then(res => {
            })
            .catch(err => {
                console.log(err.response)
                if (err.response?.data?.code === 400 && err.response?.data?.debugMessage === 'Email already verified') {
                    message = 'Your email address has already been verified. You can close the window and continue using the app now.';
                } else {
                    title = 'Email verification failed!';
                    message = 'We are unable to verify your email address. Please check your email and try again.';
                }
            })
            .finally(() => {
                removeLoader();
                showAlert(title, message, []);
            });

    };
    /** effect goes here */
    useEffect(() => {
        const params = props.match.params;
        verifyEmail(params?.id);
    }, [props.match.params]);

    /** main renderer goes here */
    return (
        <div className='parent-size d-flex align-items-center justify-content-center'></div>
    )
}
