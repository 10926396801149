import ApiConstants from '../constants/ApiConstants';
import httpClient from '../http/';
import {paramsSerializer} from '../utility/Utility';

class User {
    static getUsers(params?: any, payload?: any) {
        const paramsObj = {
            appName: 'CRM_PLUS',
            ...(params || {}),
        };
        return httpClient.get(ApiConstants.URL.USER.GET, {params: paramsObj});
    }

    static getUserObjectFromToken = (token: any) => {
        return {
            imID: token.iamUserId,
            iamUserId: token.iamUserId,
            id: token.userId,
            tenantID: token.tenantId,
            name: token.name,
            email: token.email,
            phone: token.phone_number,
            complianceEnabled: token.complianceEnabled,
            countryCode: token.countryCode,
            taxResidency: token.taxResidency,
            website: token.website,
            tenantName: token.website,
            country: token.taxResidency,
            currency: token.currency,
            isOrgSet: token.isOrgSet,
            emailVerified: token.emailVerified,
        }
    };
    static fetchUsersInfo = (params: any = {}) => {
        return httpClient.get(ApiConstants.URL.COMMON.FETCH_USER_INFO, {
            params,
            paramsSerializer: (params) => paramsSerializer('query', params)
        });
    }
}


export default User;