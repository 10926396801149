import {getCapitalized} from "../utility/Utility";

class DataParser {
    static getFormattedAddress = (address) => {
        let str = "";
        if (address === null) {
            return str;
        }

        let address1 = null;
        let address2 = null;
        let city = null;
        let state = null;

        if (
            address.address1 !== null &&
            address.address1 !== undefined &&
            address.address1.trim() !== ""
        ) {
            address1 = getCapitalized(address.address1);
            str += address1 + "\n";
        }

        if (
            address.address2 !== null &&
            address.address2 !== undefined &&
            address.address2.trim() !== "" &&
            address1 !== getCapitalized(address.address2)
        ) {
            address2 = getCapitalized(address.address2);
            str += address2 + "\n";
        }

        if (
            address.city !== null &&
            address.city !== undefined &&
            address.city.trim() !== ""
        ) {
            city = getCapitalized(address.city);
        }

        if (
            address.state !== null &&
            address.state !== undefined &&
            address.state.trim() !== "" &&
            city !== getCapitalized(address.state)
        ) {
            state = getCapitalized(address.state);
        }

        if (city !== null) {
            str += city;
            if (state !== null) {
                str += ", " + state + "\n";
            } else {
                str += "\n";
            }
        } else if (state !== null) {
            str += state + "\n";
        }

        if (
            address.country !== null &&
            address.country !== undefined &&
            address.country.trim() !== "" &&
            getCapitalized(address.country) !== state &&
            getCapitalized(address.country) !== city
        ) {
            str += getCapitalized(address.country) + " ";
        }
        if (
            address.postalCode !== null &&
            address.postalCode !== undefined &&
            address.postalCode.trim() !== ""
        ) {
            str += address.postalCode;
        }

        return str;
    };

    static getFormattedFullName = (firstName, lastName) => {
        return firstName + " " + lastName;
    };

    static getDateFormatted = (myDate) => {
        // var abbrMonths = [
        //     "Jan",
        //     "Feb",
        //     "Mar",
        //     "Apr",
        //     "May",
        //     "Jun",
        //     "Jul",
        //     "Aug",
        //     "Sep",
        //     "Oct",
        //     "Nov",
        //     "Dec",
        // ];
        var abbrDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        return (
            abbrDays[myDate.getDay()]
            // ", " +
            // myDate.getDate() +
            // " " +
            // abbrMonths[myDate.getMonth()] +
            // " " +
            // myDate.getFullYear()
        );
    };

    static formatDate(date, separator = '-') {
        date = new Date(date)
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return dd + separator + mm + separator + yyyy;
    }

    static getFullYear(date) {
        return date.getFullYear();
    }

}

export default DataParser;