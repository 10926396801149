// import API from "./api";
import { DKButton } from "deskera-ui-library";
import API from "../api";
const SaveButtonComp = ({
  tableName,
  dataSourceId,
  onTableCreated,
  dataRows,
  isLoading,
  appId,
  setIntegratedDSPopup,
}) => {
  const handleCreateTable = async () => {
    try {
      const tableConfig = {
        name: tableName,
        databaseName: "test",
        datasourceId: dataSourceId || "",
        appId: appId,
        columnsMetaData: dataRows.map((row) => ({
          dsKeyPath: row.path,
          name: row.name,
          type: row.deducedType,
        })),
      };

      const response = await API.post("/app-builder/table", tableConfig);
      onTableCreated(response);
    } catch (error) {
      console.error("Error creating table:", error);
    }
  };

  return (
    <DKButton
      className="bg-blue border-l  text-white "
      onClick={() => {
        if (!dataSourceId) return;
        setIntegratedDSPopup(false);

        handleCreateTable();
      }}
      style={{
        border: "1px",

        width: "60px",
        height: "25px",
      }}
      title="Save"
    />
  );
};

export default SaveButtonComp;
