import { DKButton } from "deskera-ui-library";
import Popup from "../common/Popup";

const FieldMapPopup = ({
  setFieldMapPopup,
  setIntegratedDSPopup,
  dataRows,
  handleNameChange,
}) => {
  return (
    <Popup
      popupWindowStyles={{
        maxWidth: "90vw",
        width: "90vw",
        height: "90vh",
        maxHeight: "90vh",
        padding: 0,
        pointerEvents: "auto",
      }}
    >
      <div className="flex justify-end gap-1 mb-10 m-4">
        <DKButton
          className="bg-green border-l  text-white "
          onClick={() => {
            setFieldMapPopup(false);
          }}
          style={{
            border: "1px",

            width: "60px",
            height: "25px",
          }}
          title="Done"
        />
        <div className="mt-4">
          <table className="w-full text-left border-collapse">
            <thead>
              <tr>
                <th className="pr-4 pb-2 border-b-2">Key Path</th>
                <th className="pr-4 pb-2 border-b-2">Sample Value</th>
                <th className="pr-4 pb-2 border-b-2">Type</th>
                <th className="pr-4 pb-2 border-b-2">Name</th>
              </tr>
            </thead>
            <tbody>
              {dataRows.map((row, index) => (
                <tr key={index}>
                  <td className="pr-4 py-2">{row.path}</td>
                  <td className="pr-4 py-2">{JSON.stringify(row.value)}</td>
                  <td className="pr-4 py-2">
                    <select
                      className="border-gray-300 border rounded p-1"
                      value={row.deducedType}
                      // onChange={(e) => handleTypeChange(e, index)}
                    >
                      <option value="text">Text</option>
                      <option value="number">Number</option>
                      <option value="textArray">Text Array</option>
                    </select>
                  </td>
                  <td className="pr-4 py-2">
                    <input
                      className="border-gray-300 border-2 rounded p-1"
                      type="text" // Set formatted key path as default value
                      value={dataRows[index].name}
                      onChange={(e) => handleNameChange(e, index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Popup>
  );
};

export default FieldMapPopup;
