import { Component, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PAGE_ROUTES } from "./managers/RouteManager";
import { DKSpinner, removeLoader, showLoader } from "deskera-ui-library";

import Empty from "./components/common/Empty";

import Utility from "./utility/Utility";

import IAM from "./services/iam";
import SideBarService from "./services/sidebar";

import InitialApiManager from "./managers/InitialApiManager";
import AppManager from "./managers/AppManager";
import TagManager from "react-gtm-module";
import { HIDE_CHAT_BUBBLE_CLASS } from "./constants/Constant";
import { isMobileAppWebView } from "./utility/GetViewportSize";
import { LOCAL_STORAGE_KEYS } from "./constants/Enum";

import EmailVerificationPage from "./pages/EmailVerificationPage";
import AdminDashboard from "./components/AdminPanel/AdminDashboard";
import AppDetail from "./components/Application/AppDetail";
import ApplicationsList from "./components/AdminPanel/ApplicationsList";
import TableIdDisplay from "./components/TableIdDisplay";
import Test from "./components/test";
import NewTable from "./components/TableCreation/NewTable";
import Home from "./components/AdminPanel/Home";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didInitialApiResponseReceived: false,
    };

    SideBarService.setIsSideBarExpanded();
  }

  componentDidMount() {
    // AppManager.redirectPageGroupRoute();

    this.initializeTagManger();
    if (AppManager.isPublicURL() || AppManager.isBookADemoURL()) {
      this.onSuccessInitialCalls(null, true);
    } else {
      this.setupAppData();
    }

    Utility.setPersistentValue(LOCAL_STORAGE_KEYS.SELECTED_ACTIVITY_VIEW, 0);
    Utility.setPersistentValue(LOCAL_STORAGE_KEYS.SELECTED_DEAL_VIEW, 0);

    if (isMobileAppWebView()) {
      document.body.classList.add(HIDE_CHAT_BUBBLE_CLASS);
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  onSuccessInitialCalls = (res = null, isCurrentRouteDataReceived = false) => {
    if (isCurrentRouteDataReceived) {
      console.log("initial api recieeved");
      this.setState({
        didInitialApiResponseReceived: true,
      });
      removeLoader();
    }
  };

  onUserAuthorizationSuccess = () => {
    InitialApiManager.makeInitialApiCalls();
    this.onSuccessInitialCalls(null, true);

    removeLoader();
  };

  onUserAuthorizationFailure = () => {
    removeLoader();
  };

  setupAppData() {
    showLoader();
    IAM.checkIfUserLoggedIn()
      .then((res) => this.onUserAuthorizationSuccess())
      .catch((err) => this.onUserAuthorizationFailure());
  }

  initializeTagManger = () => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  };

  render() {
    if (window.location.hash.startsWith("#/")) {
      window.location = window.location.hash.replace("#", "");
    }

    const { didInitialApiResponseReceived } = this.state;

    return (
      <Suspense fallback={<DKSpinner />}>
        <Routes>
          <Route
            path="/"
            element={<Navigate replace to={PAGE_ROUTES.HOME} />}
          />

          <Route
            path={PAGE_ROUTES.EMAIL_VERIFICATION}
            element={<EmailVerificationPage />}
          />
          <Route path={PAGE_ROUTES.PUBLIC_PAGE} element={<Empty />} />

          <Route
            path={PAGE_ROUTES.ADMIN_DASHBOARD}
            element={
              <AdminDashboard
                didInitialApiResponseReceived={didInitialApiResponseReceived}
              />
            }
          >
            <Route
              path={PAGE_ROUTES.APPLICATION_LIST}
              element={<ApplicationsList />}
            />
            <Route path={PAGE_ROUTES.HOME} element={<Home />} />

          </Route>
          <Route path={PAGE_ROUTES.APP_DETAILS} element={<AppDetail />}>
            <Route
              path={PAGE_ROUTES.TABLE_DETAILS}
              element={<TableIdDisplay />}
            />
            <Route path="test" element={<Test />} />
          </Route>

          <Route path={"/create-table/:appId"} element={<NewTable />} />
        </Routes>
      </Suspense>
    );
  }
}

export default App;
