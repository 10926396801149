import axios from 'axios';

console.log('base url', process.env.BASE_URL)

const API = axios.create({
    baseURL: 'https://api-dev.deskera.xyz/v1',
    withCredentials: true,
    headers: {
        'accept': '*/*',

    },

});

export default API;
