import { useEffect, useState } from "react";
import SaveButtonComp from "./SaveButtonComp";

import { Configuration, OpenAIApi } from "openai";
import { useParams } from "react-router-dom";
import API from "../api";

import {
  DKButton,
  DKIcon,
  DKIcons,
  DKInput,
  DKInputForm,
  DKLabel,
  INPUT_TYPE,
  removeLoader,
  showLoader,
} from "deskera-ui-library";
import { useNavigate } from "react-router-dom";
import ApiConstants from "../../constants/ApiConstants";
import Popup from "../common/Popup";

import FieldMapPopup from "./FieldMapPopup";

export const formatKeyPath = (keyPath) => {
  let newValue = keyPath
    .split("_") // Split by underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join words with spaces

  return newValue;
};

const NewTable = () => {
  const navigate = useNavigate();

  const { appId } = useParams();
  const [dataSources, setDataSources] = useState([]);
  const [tableName, setTableName] = useState("");
  const [dataSourceId, setDataSourceId] = useState("");
  const [dataRows, setDataRows] = useState([]);
  const [dataSource, setDataSource] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [tableId, setTableId] = useState("");
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [fromScratchPopup, setFromScratchPopup] = useState(false);
  const [integratedDSPopup, setIntegratedDSPopup] = useState(false);
  const [fieldMapPopup, setFieldMapPopup] = useState(false);
  const [queryProp, setQueryProp] = useState("");
  const [integratedApps, setIntegratedApps] = useState([]);
  const [fieldPath, setFieldPath] = useState("");

  const fetchIntegratestDSList = async () => {
    API.get("/app-builder/datasource", { params: { q: queryProp } })

      .then((response) => {
        console.log("Data Source Response:", response);
        setDataSources(response.data.data);
      })
      .catch((error) => console.error("Error fetching data sources:", error));
  };
  const fetchIntegratedApps = async () => {
    try {
      const response = await API.get("/app-builder/integrated-app");
      const { data, totalCount } = response.data;
      setIntegratedApps(data);
    } catch (error) {
      console.error("Error fetching integrated apps:", error);
    }
  };

  useEffect(() => {
    fetchIntegratedApps();
    fetchIntegratestDSList();
  }, [queryProp]);

  useEffect(() => {
    if (isLoading) showLoader("Wait we are setting everything for you!");
    else removeLoader();
  }, [isLoading]);

  const fetchData = async (ds) => {
    const id = ds._id;
    setIsLoading(true);

    try {
      const response = await API.get(`/app-builder/datasource/${id}/record`);
      const data = response.data;
      let fieldPath = ds.metadata.fieldPath;
      console.log("fieldPath", fieldPath)
      const parsedData = parseDataByPath(data, fieldPath);
      setFieldPath(fieldPath);
      const transformedData = await transformDataWithOpenAI(parsedData);

      if (transformedData) {
        const rows = Object.keys(transformedData).map((key) => ({
          path: key,
          value: transformedData[key],
          deducedType: deduceType(transformedData[key]),
          name: formatKeyPath(key),
        }));
        setDataRows(rows); // Adjust this according to how you process the data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNextClick = () => {
    setIsNextClicked(true);
  };
  // const handleOnScratch
  const onTableCreated = (data) => {
    setTableId(data.data.id);
    setShowSuccessMessage(true);
    navigate(`/app/${appId}`);
  };

  const jsonIntegratedApps =
    integratedApps.map((app) => ({
      ds_parent_name: app.ds_parent_name,
      img_url: app.img_url,
      // Add other properties based on your data structure
    })) || [];

  const deduceType = (value) => {
    if (Array.isArray(value)) return "text-array";
    if (typeof value === "number") return "number";
    return "text"; // Default to text for simplicity
  };
  const transformDataWithOpenAI = async (parsedData) => {
    setIsLoading(true); // Start loading
    const prompt = `
              User will give you a json which will have details about path of a certain data and value of that data.
              
              Your Job is to form a new JSON of following format:
              {
                <path>: <its value>
              }
          
              Remember these rules very strictly:
              1) Your response should be a json obj and have no explanation, no extra text just a json object
              2) It should have no extra text and no explanation, only a json object.
          
              User gave this json:
              
              ${JSON.stringify(parsedData)}
              `;
    const key = ApiConstants.OPENAI_API_KEY;
    if (!key) {
      console.error("No OpenAI key found");
      setIsLoading(false); // Stop loading if there's an error
      return null;
    }

    const openai = new OpenAIApi(new Configuration({ apiKey: key }));
    try {
      const completion = await openai.createChatCompletion({
        model: "gpt-4-0125-preview",
        messages: [{ role: "system", content: prompt }],
        max_tokens: 1000,
        temperature: 0,
      });

      const response = completion.data.choices[0].message?.content?.trim();
      const jsonResponse = JSON.parse(response);
      setIsLoading(false); // Stop loading after receiving response
      return jsonResponse;
    } catch (error) {
      console.error("Error parsing JSON from OpenAI response", error);
      setIsLoading(false); // Stop loading if there's an error
      return null;
    }
  };
  const handleTypeChange = (e, index) => {
    const newDataRows = [...dataRows];
    newDataRows[index].deducedType = e.target.value;
    setDataRows(newDataRows);
  };

  const handleNameChange = (e, index) => {
    const newDataRows = [...dataRows];
    newDataRows[index].name = e.target.value;
    setDataRows(newDataRows);
  };
  const parseDataByPath = (data, path) => {
    try {
      const parts = path.replace(/\[(\w+)\]/g, ".$1").split("."); // Handles both dot notation and brackets
      let currentPart = data;
      for (let part of parts) {
        if (currentPart[part] === undefined) {
          console.log(`Path not found: ${path} at ${part}`);
          return undefined; // Or any default value you see fit
        }
        currentPart = currentPart[part];
      }
      return currentPart;
    } catch (error) {
      console.error(`Error parsing data by path: ${path}`, error);
      return undefined; // Or any default value you see fit
    }
  };
  const onIntegratedDSButtonClicked = () => {};

  const CardIntegratedDS = ({ title, imageUrl, qr_string }) => (
    <button
      onClick={() => {
        setIntegratedDSPopup(true);
        setQueryProp(title);
      }}
      className="card w-36 h-36 flex flex-col bg-slate-200 rounded-lg justify-center gap-2 items-center"
    >
      <img className="w-14 h-14 mx-auto" src={imageUrl} alt={title} />

      <p className="fs-m fw-l text-center">{title}</p>
    </button>
  );

  // Your component that renders the cards
  const CardIntegratedDSList = ({ cards }) => (
    <div className="card-container flex gap-8">
      {cards.map((card, index) => (
        <CardIntegratedDS key={index} {...card} />
      ))}
    </div>
  );
  const cardsIntegratedDSArray = jsonIntegratedApps.map((item) => ({
    title: item.ds_parent_name,
    imageUrl: item.img_url,
    qr_string: item.qr_param,
    // Add more properties as needed
  }));
  return (
    <div className="p-4 w-full">
      <div className="flex mt-10">
        {" "}
        <div className="fs-xxxl fw-b  mx-auto ">
          How would you like to Create your Table?
        </div>
      </div>
      <div className="flex">
        <DKLabel
          text="On my own"
          className=" fs-xl  mt-6   mx-auto fw-l"
          style={{}}
        />
      </div>

      <div className="flex justify-center gap-8 mt-6">
        <button
          className="w-36 h-36  bg-cyan-100 shadow-md hover:shadow-xl duration-100 transition rounded-xl"
          onClick={() => {
            setFromScratchPopup(true);
          }}
        >
          <DKIcon src={DKIcons.ic_plus} className=" mx-auto" />
          <p className="mx-auto fs-m mt-3">From Scratch</p>
        </button>
        <button className="  w-36 h-36 bg-cyan-100 shadow-md rounded-xl">
          <DKIcon src={DKIcons.ic_books} className=" mx-auto" />
          <p className="mx-auto fs-m mt-3">Import with Data</p>
        </button>
      </div>
      {fromScratchPopup && (
        <Popup
          popupWindowStyles={{
            pointerEvents: "auto",
          }}
          onClose={() => {
            setFromScratchPopup(false);
          }}
        >
          <DKInputForm
            className="border-radius-s bg-white  w-full"
            direction="HORIZONTAL"
            fields={[
              {
                key: "name", // Change the key to 'name'
                placeholder: "Enter Table name",
                required: true,
                title: "Table name",
                type: "text",
                //   validator: () => {},
                value: "",
              },
            ]}
            onCancel={() => {
              setFromScratchPopup(false);
            }}
            onChange={(fieldValues) => {
              console.log("Field values changed:", fieldValues);
            }}
            onSubmit={async (data) => {
              const apiData = {
                databaseName: "test",
                name: data[0].value,
                appId: appId,
              };

              try {
                const response = await API.post("/app-builder/table", apiData);

                navigate(`/app/${appId}`);

                // Corrected reference
              } catch (error) {
                console.error("Error while creating the application", error);
              }

              setFromScratchPopup(false);
            }}
            style={{
              width: 450,
            }}
            title="New Table from Scratch"
          />
        </Popup>
      )}
      <div className="flex">
        <DKLabel
          text="Using an Integrated Datasource"
          className=" fs-xl  mt-6   mx-auto fw-l"
          style={{}}
        />
      </div>

      <div className="flex justify-center mt-6 ">
        <CardIntegratedDSList cards={cardsIntegratedDSArray} />
      </div>

      {integratedDSPopup && (
        <Popup
          popupWindowStyles={{
            maxWidth: "90vw",
            width: 450,
            // height: "auto",
            maxHeight: "90vh",

            pointerEvents: "auto",
          }}
          onClose={() => {
            setIntegratedDSPopup(false);
          }}
        >
          <div
            style={{
              width: 400,
              height: "auto",
            }}
          >
            <div className="box-border">
              <div className="flex justify-end gap-1 mb-10">
                <DKButton
                  className="bg-gray-200 border-l  text-gray-700"
                  onClick={() => {
                    setIntegratedDSPopup(false);
                  }}
                  style={{
                    border: "1px",

                    width: "70px",
                    height: "25px",
                  }}
                  title="Cancel"
                />

                <SaveButtonComp
                  tableName={tableName}
                  dataSourceId={dataSourceId}
                  onTableCreated={onTableCreated}
                  dataRows={dataRows}
                  setIsLoading={setIsLoading}
                  appId={appId}
                  setIntegratedDSPopup={setIntegratedDSPopup}
                />
              </div>

            </div>
            <div>
              <DKInput
                title="Table Name"
                type="text"
                className="mt-2"
                direction="HORIZONTAL"
                errorMessage="Enter valid field value"
                value={tableName}
                onChange={(data) => {
                  setTableName(data);
                }}
                onClick={() => {}}
              />
              <DKInput
                  className="mt-4 flex "
                  value={dataSource}
                  formatter={(obj) => {
                    return obj.name;
                  }}
                  title="Select Datasource:"
                  type={INPUT_TYPE.DROPDOWN}
                  required={false}
                  onChange={(value) => {
                    // setDataSource(selectedDataSource);
                  }}
                  dropdownConfig={{
                    // className: "p-2",
                    style: {},
                    allowSearch: true,
                    searchableKey: "name",
                    data: dataSources,
                    renderer: (index, obj) => {
                      return <DKLabel text={`${obj.name}<br>`} />;
                    },
                    onSelect: (index, value) => {
                      const selectedId = value._id; // Assuming _id is the unique identifier in your data source
                      const selectedDataSource = dataSources.find(
                          (ds) => ds._id === selectedId
                      );
                      setDataSourceId(selectedId);
                      setDataSource(selectedDataSource);
                      fetchData(selectedDataSource);
                    },
                  }}
              />
            </div>

            <button
              onClick={() => setFieldMapPopup(true)}
              className="underline mt-12 fs-m fw-m text-violet-800 "
            >
              Change field Mapping
            </button>
          </div>
        </Popup>
      )}
      {fieldMapPopup && (
        <FieldMapPopup
          setIntegratedDSPopup={setIntegratedDSPopup}
          setFieldMapPopup={setFieldMapPopup}
          dataRows={dataRows}
          handleNameChange={handleNameChange}
        />
      )}
    </div>
  );
};

export default NewTable;
