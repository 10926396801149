import ApiConstants from "../constants/ApiConstants";
import {removeLoader, showLoader} from "deskera-ui-library";
import http from "../http";
import IAMManager from "../managers/IAMManager";
import Utility from "../utility/Utility";
import ApiManager from "../managers/ApiManager";
import AppManager from "../managers/AppManager";
import UserManager from "../managers/UserManager";

export default class IAM {
    static getLoginStatus(params) {
        return http.get(ApiConstants.URL.IAM, {params: params}).then(
            (res) => {
                return Promise.resolve(res);
            },
            (err) => {
                return Promise.reject(err);
            }
        );
    }

    static logOut() {
        showLoader("Logging out...");
        return http.get(ApiConstants.URL.IAM.LOG_OUT_V2).then(
            (res) => {
                removeLoader();
                return Promise.resolve(res);
            },
            (err) => {
                removeLoader();
                return Promise.reject(err);
            }
        );
    }

    static switchTenant(params) {
        showLoader("Switching organisation...");
        Utility.setPersistentValue("selectedPipeline", 0);
        Utility.setPersistentValue("selectedDealView", 0);
        return http.post(ApiConstants.URL.IAM.SWITCH_TENANT, params).then(
            (res) => {
                removeLoader();
                return Promise.resolve(res);
            },
            (err) => {
                removeLoader();
                return Promise.reject(err);
            }
        );
    }

    static getTwoFactorAuthStatus(params) {
        showLoader("Getting status...");
        return http.get(ApiConstants.URL.IAM.TFA_STATUS, {params: params}).then(
            (res) => {
                removeLoader();
                IAMManager.setTFAStatus(res);
                return Promise.resolve(res);
            },
            (err) => {
                removeLoader();
                return Promise.reject(err);
            }
        );
    }

    /**
     *  @todo : To Use axios wrapper,
     * Using Fetch currently as axios will require handling intercepted response error alerts
     */
    static async checkIfUserLoggedIn() {
        return fetch(ApiConstants.URL.BASE + ApiConstants.URL.IAM.STATUS, {
            method: "GET",
            credentials: "include",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Credentials": "true",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.accessToken) {
                    ApiManager.tokenReceived(data.accessToken, data.emailVerified);
                    return Promise.resolve("Authenticated User");
                } else {
                    AppManager.gotoLoginPage(false);
                    return Promise.reject("Failed to authenticate user");
                }
            })
            .catch((error) => {
                AppManager.gotoLoginPage(false);
                /* Rejecting with no message to avoid showing app ui */
                return Promise.reject("");
            });
    }

    static async checkIfUserSubscribed() {
        return http
            .get(ApiConstants.URL.SUBSCRIPTION.SALES)
            .then((data) => {
                UserManager.setUserPlanDetails(data);
                if (UserManager.isPlanActive()) {
                    return Promise.resolve("Authorized User");
                } else {
                    return Promise.reject(data);
                }
            })
            .catch((error) => {
                return Promise.reject("Failed to fetch user subscription");
            });
    }
}
