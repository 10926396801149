import {matchPath} from "react-router-dom";
import NavigateContext from '../Helper/NavigationContext';




export const ROUTE_ID_IDENTIFIER = ":id";

export const ROUTE_APP_DETAIL_ID_IDENTIFIER = ":appId";

export const ROUTE_TABLE_DETAIL_ID_IDENTIFIER = ":tableId";

export const PAGE_ROUTES = {

    PUBLIC_PAGE_PATH: "/public/",
    EMAIL_VERIFICATION: "/public/email-verification/" + ROUTE_ID_IDENTIFIER,
    PUBLIC_PAGE: "/public/" + ROUTE_ID_IDENTIFIER,

    HOME: "/admindashboard/home",

    ADMIN_DASHBOARD: "/admindashboard",

    APPLICATION_LIST: "/admindashboard/applications",

    APP_DETAILS: "/app/" + ROUTE_APP_DETAIL_ID_IDENTIFIER,

    TABLE_DETAILS: "table/" + ROUTE_TABLE_DETAIL_ID_IDENTIFIER,

    DESKERA_HOME: "/public/deskera-home",
    PRICING_HOME: "/public/pricing",

    MOBILE_PAGE_PATH: "/mobile-app/",
    MOBILE_APP_LANDING: '/mobile-app/landing',

    NO_ACCESS: "/no-access",


    /* Menu Item 9: Logs */
    LOGS: '/logs',
};


export const PAGE_GROUP_REDIRECTS = {
    //not useful right now for app-builder
};

export default class RouteManager {
    static presenter = null;

    static setPresenter(presenter) {
        this.presenter = presenter;
    }

    static context = NavigateContext; // Assuming this setup, adjust based on actual implementation

    static navigateToPage(pageRoute, param = null) {
        const navigate = this.context;
        if (navigate) {
            navigate(pageRoute + (param ? "?" + param : ""));
        } else {
            console.error("Navigate function not found.");
        }
    }

    static navigateToHome() {
        RouteManager.navigateToPage(PAGE_ROUTES.ADMIN_DASHBOARD);
    }

    static replacePageParams(pageRoute = window.location.pathname, param = '') {
        RouteManager.presenter?.props.history?.replace(
            pageRoute + (param ? "?" + param : "")
        );
    }

    static isCurrentRoute(route) {
        return matchPath(window.location.pathname, route)?.isExact;
    }
}
