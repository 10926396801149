import {LOCAL_STORAGE_KEYS} from "../constants/Enum";
import {isViewportLarge} from "../utility/GetViewportSize";
import Utility from "../utility/Utility";

class SideBarService {
    static sideBarExpandedWidth = 230;
    static sideBarCollapsedWidth = 0;
    static isSideBarExpanded = true;

    static setIsSideBarExpanded() {
        if (isViewportLarge()) {
            SideBarService.isSideBarExpanded = true;
        } else {
            SideBarService.isSideBarExpanded =
                Utility.getPersistentValue(
                    LOCAL_STORAGE_KEYS.IS_SIDE_BAR_EXPANDED
                )?.toString() === "true" || false;
        }
    }

    static triggerSidebarChangeEvent() {
        dispatchEvent(
            new CustomEvent("onSideBarChange", {
                detail: {isExpanded: SideBarService.isSideBarExpanded}
            })
        );
    }

    static toggleSideBar = () => {
        SideBarService.isSideBarExpanded = !SideBarService.isSideBarExpanded;
        SideBarService.triggerSidebarChangeEvent();
        Utility.setPersistentValue(
            LOCAL_STORAGE_KEYS.IS_SIDE_BAR_EXPANDED,
            SideBarService.isSideBarExpanded
        );
    };

    static getContainerWidth = (excludePadding?: boolean): number => {
        if (isViewportLarge()) {
            return (
                window.innerWidth -
                SideBarService.sideBarExpandedWidth -
                (excludePadding ? 0 : 48)
            );
        } else {
            return (
                window.innerWidth * (excludePadding ? 1 : 0.92)
            ); /* as 4% padding present on mainContainer */
        }
    };

    static getCurrentMenuWidth = (): number => {
        return SideBarService.isSideBarExpanded
            ? SideBarService.sideBarExpandedWidth
            : SideBarService.sideBarCollapsedWidth;
    };
}

export default SideBarService;
