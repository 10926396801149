// AppDetail.js
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { DKSidebar } from "deskera-ui-library";
import AppManager from "../../managers/AppManager";
import SideBarService from "../../services/sidebar";
import API from "../api";
import TopMenu from "./TopMenu";
// Sample menu items

import ic_tables from "../../assets/menu/white/ic_dashboard.png";
import ic_table from "../../assets/menu/white/ic_list.png";

const AppDetail = () => {
  const { appId } = useParams();
  const [app, setApp] = useState(null);
  const [tables, setTables] = useState([]);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

  // Fetch the tables list
  useEffect(() => {
    const fetchAppName = async () => {
      try {
        const response = await API.get(`/app-builder/app/${appId}`);
        setApp(response.data);
      } catch (error) {
        console.error("Failed to fetch application name:", error);
      }
    };

    fetchAppName();

    const fetchTables = async () => {
      try {
        const response = await API.get(
          `/app-builder/table?includeFilters=false&appId=${appId}`
        );
        setTables(response.data.data);
        navigate(`table/${response.data.data[0]._id}`);
      } catch (error) {
        console.error("Failed to fetch tables:", error);
      }
    };

    fetchTables();
  }, [appId]);

  const handleExpandCollapse = () => {
    setIsSidebarExpanded(!isSidebarExpanded);
  };

  const customHeaderRenderer = () => {
    return (
      <div
        style={{
          padding: "10px",
          fontSize: "18px",
          color: "white",
          fontWeight: "bold",
        }}
      >
        {app.name}
      </div>
    );
  };
  const navigate = useNavigate();

  const menuItemList =
    tables.length > 0
      ? tables.map((table) => ({
          name: table.name,
          icon: ic_table,
          onClick: () => navigate(`table/${table._id}`),
          route: `/app/${appId}/table/${table._id}`,
        }))
      : [];

  return (
    <div className="main-holder bg-gray1 row align-items-start app-font">
      {app && (
        <DKSidebar
          menuItemList={[
            {
              name: "Tables",
              className: "left-menu-group",
              icon: ic_tables,
              visible: true,
              expandByDefault: true,
              subItems: menuItemList,
                onClick: ()=> {}
            },
          ]}
          collapsedWidth={0}
          expandedWidth={230}
          isMenuExpanded={isSidebarExpanded}
          onExpandCollapse={handleExpandCollapse}
          customHeaderRenderer={customHeaderRenderer}
          allowTenantSelection={false}
        />
      )}
      <div
        className="parent-width parent-height column"
        style={{
          flex: 1,
          maxWidth: SideBarService.getContainerWidth(true),
        }}
      >
        {!AppManager.isPublicURL() && !AppManager.isBookADemoURL() && app && (
          <TopMenu app={app} />
        )}

        <div className={"px-8 my-8 w-[100%] overflow-y-auto h-full"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AppDetail;
