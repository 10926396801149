import {
  DKInput,
  DKInputForm,
  DKLabel,
  DKSpinner,
  showAlert,
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteApplication,
  fetchApplications,
  renameApplication,
} from "../../redux/slices/applicationSlice";
import Popup from "../common/Popup";
import ApplicationCard from "./ApplicationCard";
const ApplicationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activePopup, setActivePopup] = useState({
    isOpen: false,
    type: null,
    appId: null,
  });
  const [searchTerm, setSearchTerm] = useState("");
  const { applications, isLoading, error } = useSelector(
    (state) => state.applications
  );

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      dispatch(fetchApplications(searchTerm));
    }, 1000);
    return () => clearTimeout(delayDebounce);
  }, [searchTerm]);

  useEffect(() => {
    fetchData(searchTerm);
  }, [searchTerm]);

  const fetchData = async (searchTerm) => {
    await dispatch(fetchApplications(searchTerm));
  };

  const handleCardClick = (appId) => {
    navigate(`/app/${appId}`);
  };

  const handleRenameClick = async (appId, newName) => {
    try {
      console.log("rename", appId, newName);
      const actionResult = await dispatch(
        renameApplication({ appId, newName })
      );
      if (renameApplication.fulfilled.match(actionResult)) {
        await fetchData(searchTerm);
      }
      closePopup();
    } catch (error) {
      showAlert("Failed to rename application");
    }
  };

  const handleDeleteClick = async (appId) => {
    try {
      closePopup();
      const actionResult = await dispatch(deleteApplication(appId));
      if (deleteApplication.fulfilled.match(actionResult)) {
        await fetchData(searchTerm);
      }
    } catch (error) {
      showAlert("Failed to delete application");
    }
  };

  const openPopup = (type, appId) => {
    setActivePopup({ isOpen: true, type, appId });
  };

  const closePopup = () => {
    setActivePopup({ isOpen: false, type: null, appId: null });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "1rem",
          width: "100%",
        }}
      >
        {isLoading && <DKSpinner title="Loading..." />}
        {error && showAlert(error)}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: "1rem",
          }}
        >
          <DKLabel
            text="My Applications"
            className="fs-l font-semibold  flex-1"
          />
          <div className="w-[40%] h-[40px]">
            <DKInput
              placeholder="Search Applications..."
              value={searchTerm}
              onChange={setSearchTerm}
              className="h-full"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 gap-4 w-full overflow-y-auto">
          {applications.map((app) => (
            <ApplicationCard
              key={app._id}
              app={app}
              onCardClick={handleCardClick}
              handleRename={() => openPopup("rename", app._id)}
              handleDelete={() => openPopup("delete", app._id)}
            />
          ))}
        </div>
      </div>

      {/* {activePopup.isOpen && activePopup.type === "options" && (
        <DKListPicker
          data={["Rename", "Delete"]}
          onSelect={(index, value) => {
            if (value === "Rename") openPopup("rename", activePopup.appId);
            else if (value === "Delete") openPopup("delete", activePopup.appId);
          }}
          onClose={closePopup}
          style={{ position: "absolute", right: "5px", top: "30px" }} // Adjust positioning as needed
          icons={[DKIcons.ic_edit, DKIcons.ic_delete]}
        />
      )} */}

      {activePopup.type === "rename" && activePopup.isOpen && (
        <Popup onClose={closePopup}>
          <DKInputForm
            fields={[
              {
                key: "newName",
                placeholder: "Enter New Application Name",
                required: true,
                title: "New Name",
                value:applications.find(obj => activePopup.appId === obj._id).name,
                type: "text",
              },
            ]}
            onCancel={closePopup}
            onSubmit={(data) =>
              handleRenameClick(
                activePopup.appId,
                data.find((obj) => obj.key === "newName").value
              )
            }

            title="Rename Application"
          />
        </Popup>
      )}

      {activePopup.type === "delete" &&
        activePopup.isOpen &&
        showAlert("Are you sure you want to delete this application?", "", [
          {
            title: "Delete",
            className: "bg-red mr-r text-white",
            onClick: () => handleDeleteClick(activePopup.appId),
          },
          {
            title: "Cancel",
            onClick: closePopup,
          },
        ])}
    </>
  );
};

export default ApplicationList;
