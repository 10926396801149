import ApiConstants from "../../constants/ApiConstants";
import httpClient from "../../http";

export class EmailService {
    /**
     * @deprecated No longer functional
     * @description - Used to send email
     */
    static sendEmail(payload: IEmailPayload, config = {}) {
        return httpClient.post(ApiConstants.URL.COMMON.SEND_EMAIL, this.convertJSONToEmailPayload({
            senderName: 'Deskera',
            recipientName: 'Deskera',
            ...payload
        }), config);
    }

    static convertJSONToEmailPayload(json: IEmailPayload) {
        let emailPayload: FormData = new FormData();
        Object.keys(json).map(key => {
            // @ts-ignore
            emailPayload.append(key, json[key]);
        });
        return emailPayload;
    }

    static verifyEmail(payload: { id: string }) {
        return httpClient.get(ApiConstants.URL.WORKFLOW.VERIFY_EMAIL(payload.id), {params: {skipInterceptor: true}});
    }

    static connectOauthIntegration(payload: any) {
        return httpClient.get(ApiConstants.URL.OFFICE365.OAUTH(payload));
    }
}

export interface IEmailPayload {
    body: any;
    recipientEmail: any;
    recipientName?: any;
    subject: any;
    senderName?: any;
}