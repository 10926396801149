import { isEmpty } from "./DKUtility";

import {
  FORMULA_TOKEN,
  FORMULA_TOKEN_TYPES,
  INPUT_TYPE,
} from "../constants/Enum";
import { evaluate } from "mathjs";

export default class DataGridUtility {
  static getFormElements(columnsData) {
    return columnsData.map((column) => {
      return {
        ...column,
        title: column.name,
        options: isEmpty(column.options)
          ? []
          : column.options.map((option) => {
              return option.name;
            }),
      };
    });
  }

  static getRowData(formData, columns) {
    let rowData = {};
    formData.forEach((obj) => {
      if (obj.value !== undefined && obj.value !== null) {
        if (
          obj.type === INPUT_TYPE.SELECT ||
          obj.type === INPUT_TYPE.MULTI_SELECT
        ) {
          const columnData = columns.filter((column) => {
            return obj.key === column.key;
          })[0];
          const option_values = obj.value.map((index) => {
            return columnData.options[index].id;
          });
          rowData[obj.key] = option_values;
        } else {
          rowData[obj.key] = obj.value;
        }
      }
    });
    return rowData;
  }
}

function calculator({ operator, operand1, operand2 }) {
  switch (operator) {
    case FORMULA_TOKEN.PLUS:
      return operand1 + operand2;
    case FORMULA_TOKEN.MINUS:
      return operand1 - operand2;
    case FORMULA_TOKEN.MULTIPLY:
      return operand1 * operand2;
    case FORMULA_TOKEN.DIVIDE:
      return operand1 / operand2;
    case FORMULA_TOKEN.MOD:
      return operand1 % operand2;
    case FORMULA_TOKEN.POWER:
      return Math.pow(operand1, operand2);
    default:
      throw new Error(`Invalid operator: ${operator}`);
  }
}

export function computeExpressionValueCustom(
  formulaTokens,
  dynamicOperandValues
) {
  if (!formulaTokens.length) {
    throw new Error("Invalid formula");
  }

  let tokenIndex = 0;
  const operatorStack = [],
    operandStack = [];

  while (tokenIndex < formulaTokens.length) {
    const tokenData = formulaTokens[tokenIndex];

    if (tokenData.type === FORMULA_TOKEN_TYPES.GROUP) {
      /* Formula Group must start with Opening parenthesis */
      if (tokenData.value !== FORMULA_TOKEN.OPEN) {
        throw new Error("Invalid formula grouping");
      }

      /**
       * Finding matching closing parenthesis for current group
       * and adding all tokens in between to a new token list for computing separately
       */
      let tokenGroup = [],
        groupTokenIndex = 1;
      while (groupTokenIndex !== 0) {
        tokenIndex++;
        if (tokenIndex >= formulaTokens.length) {
          throw new Error("Invalid formula grouping");
        }

        const groupTokenData = formulaTokens[tokenIndex];
        tokenGroup.push(groupTokenData);

        if (groupTokenData.type === FORMULA_TOKEN_TYPES.GROUP) {
          groupTokenIndex =
            groupTokenIndex +
            (groupTokenData.value === FORMULA_TOKEN.OPEN ? 1 : -1);
        }
      }

      /* Removing matching closing parenthesis from current group tokens list */
      tokenGroup.pop();

      const groupComputedValue = computeExpressionValue(
        tokenGroup,
        dynamicOperandValues
      );
      operandStack.push(groupComputedValue);
    } else if (tokenData.type === FORMULA_TOKEN_TYPES.OPERAND) {
      let operandValue = tokenData.value;
      if (tokenData.dynamic) {
        operandValue = dynamicOperandValues[tokenData.value];
      }

      if (isNaN(operandValue)) {
        throw new Error("Invalid operand value in formula");
      } else if (!operandValue) {
        operandValue = 0;
      }

      operandStack.push(operandValue);
    } else if (tokenData.type === FORMULA_TOKEN_TYPES.OPERATOR) {
      operatorStack.push(tokenData.value);
    }

    tokenIndex++;
  }

  let operator = operatorStack.pop();
  while (operator) {
    const operand2 = operandStack.pop();
    const operand1 = operandStack.pop();
    const computedValue = calculator({ operator, operand1, operand2 });
    operandStack.push(computedValue);
    operator = operatorStack.pop();
  }

  const computedValue = operandStack.pop();
  return computedValue;
}

export function computeExpressionValue(formulaTokens, dynamicOperandValues) {
  if (!formulaTokens.length) {
    throw new Error("Empty expression");
  }

  let formulaString = "",
    returnBlank = false;
  formulaTokens.forEach((tokenData) => {
    if (tokenData.type === FORMULA_TOKEN_TYPES.OPERAND) {
      let operandValue = tokenData.value;
      if (tokenData.dynamic) {
        operandValue = dynamicOperandValues[tokenData.value];

        /* If any field value is blank, then need to show blank in computed value */
        returnBlank = returnBlank || operandValue === "";
      }

      if (isNaN(operandValue)) {
        throw new Error(
          tokenData.dynamic
            ? `Invalid value for ${tokenData.token} field present in formula, please verify if the field exists.`
            : "Invalid numeric operand in formula, please re-verify."
        );
      } else if (!operandValue) {
        operandValue = 0;
      }

      formulaString += operandValue;
    } else {
      formulaString += tokenData.value;
    }
  });

  if (returnBlank) {
    return "";
  }

  let computedValue = evaluate(formulaString);
  if (isNaN(computedValue)) {
    throw new Error("Invalid value, please verify formula.");
  } else {
    computedValue = Number(Number(computedValue).toFixed(2));
  }

  return computedValue;
}

export function isGridColumnFreezed(column) {
  return column && column.freezed && !column.disableFreeze;
}
