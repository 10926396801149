import {DKDataGrid, DKInputForm, DKInput, DKLabel, DKButton, DKIcons, removeLoader, showAlert, showLoader, showToast} from "deskera-ui-library";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// import ControlledInput from "./common/ControlledInput";
import { useParams } from "react-router-dom";
import API from "./api";
import Popup from "./common/Popup";
import { showAddColumnPopup } from "./AddColumnPopup";
function TableIdDisplay() {
  const navigate = useNavigate();

  const { appId } = useParams();
  const { tableId } = useParams();
  const [data, setData] = useState(null);
  const [recordData, setRecordData] = useState([]);
  const [dsrecordData, setDsrecordData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [columnData, setColumnData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState([]);
  const [showNameFieldModal, setShowNameFieldModal] = useState(false);
  const [refNameFieldMap, setRefNameFieldMap] = useState({});
  const [dropdownOptionsLoaded, setDropdownOptionsLoaded] = useState(false);
  const [newTableName, setNewTableName] = useState('');
  const [showEditPopup, setShowEditPopup] = useState(false);
  
  const fetchRefTableData = useCallback(async (refTableId) => {
    try {
      const response = await API.get(`/app-builder/table/${refTableId}/record`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch ref table (${refTableId}) data:`, error);
      return null;
    }
  }, []);

  const createRefNameFieldMap = useCallback(async (columnsMetaData) => {
    const map = {};

    for (const column of columnsMetaData) {
      if (column.type === 'ref' && column.refTable && column.refTable._id) {
        const refTableId = column.refTable._id;
        const currentColId = column.id;
        const nameField = await fetchRefTableData(column.refTable._id);
        if (nameField) {
          map[refTableId] = { nameFieldId: nameField, currentColId };
        }
      }
    }

    setRefNameFieldMap(map);
  }, [fetchRefTableData]);

  const processRowData = (data) => {
    const rowData = data.rowData;
    const result = {};
  
    Object.keys(rowData).forEach((key) => {
      if (key.endsWith("_detail")) {
        return;
      }
  
      const detailKey = `${key}_detail`;
      if (rowData.hasOwnProperty(detailKey)) {
        const detail = rowData[detailKey][0];
        const value = Object.values(detail.cells)[0]; 
        result[key] = { _id: detail._id, value };
      } else {
        result[key] = rowData[key];
      }
    });
  
    Object.keys(result).forEach((key) => {
      if (key.endsWith("_detail")) {
        delete result[key];
      }
    });
  
    return result;
  };

  const updateDropdownOptions = useCallback(async () => {
    if (columnData) {
      let updatedColumnData = [...columnData];
  
      for (const refTableId of Object.keys(refNameFieldMap)) {
        const { nameFieldId, currentColId } = refNameFieldMap[refTableId];
        const refData = await fetchRefTableData(refTableId);
        const nameColId = Object.keys(nameFieldId?.[0]?.cells || {})[0]
        const dropdownOptions = refData.map(record => (
          {
            "_id": record._id,
            "value":  record.cells[nameColId]
          }
        ));
        const columnIndex = columnData.findIndex(col => col.id === currentColId);
        if (columnIndex !== -1) {
          updatedColumnData[columnIndex].dropdownConfig.data = dropdownOptions;
          console.log("test",updatedColumnData[columnIndex].options)
          // updatedColumnData[columnIndex].dropdownConfig.onSelect = ((data)=>console.log("DO",dropdownOptions));
          updatedColumnData[columnIndex].formatter = ((data) => {
            data = processRowData(data)
            if (data[currentColId]!==undefined) {
              return JSON.stringify(data[currentColId].value).replace(/^"|"$/g, '');
            }
            else {
              return "";
            }
          })
        }
      }
      setColumnData(updatedColumnData);
    }
  }, [columnData, fetchRefTableData, refNameFieldMap]);

  useEffect(() => {
    if (data && data.columnsMetaData) {
      createRefNameFieldMap(data.columnsMetaData);
      console.log('Current refNameFieldMap:', refNameFieldMap);
    }
  }, [data, createRefNameFieldMap]);
  useEffect(() => {
    if (Object.keys(refNameFieldMap).length > 0 && !dropdownOptionsLoaded) {
      updateDropdownOptions().then(() => {
        setDropdownOptionsLoaded(true);
      });
    }
  }, [refNameFieldMap, updateDropdownOptions, dropdownOptionsLoaded]);
  const handleEditIconClick = () => {
    setShowEditPopup(true);
  };

  const handleTableNameChange = (event) => {
    setNewTableName(event.target.value);
  };

  const handleUpdateTableName = async (newName) => {
    try {
      const response = await API.put(`/app-builder/table/${tableId}`, {
        name: newName,
      });
      console.log("Table name updated", response.data);
      fetchData(); // Refresh data to reflect changes

      removeLoader();
    } catch (error) {
      console.error("Failed to update table name:", error);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [currentFilters, setCurrentFilters] = useState({
    query: [],
    logicalOperator: "and",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const pageSize = 30;

  const totalPageCount = Math.ceil(totalRecords / pageSize);
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    console.log("tableId", tableId);
    try {
      const response = await API.get(`/app-builder/table/${tableId}`);
      const result = response.data;
      setData(result);

      const convertedArray = result.columnsMetaData.map((item) => ({
        key: item.id,
        title: item.name,
        type: item.type,
        options: (item.options || []).map((e) => e.name),
        required: item.required,
        editable: item.editable,
      }));
      setFormData(convertedArray);

      const tableConvertedArray = result.columnsMetaData.map((item) => {
        const element = {
          key: item.id,
          id: item.id,
          filter: true,
          name: item.name,
          type: item.type,
          options: item.options,
          systemField: item.systemField,
          required: item.required,
          editable: item.editable,
          hidden: item.hidden,
          uiVisible: item.uiVisible,
          textAlign: item.textAlign,
          formula: item.formula || null,
          width: item.width,
        };
        if (item.type === 'ref') {
          element.type = "dropdown";
          element.dropdownConfig = {
            allowSearch: true,
            onSelect: (index, obj) => {
             
            }, 
            renderer: (index, obj) => {

              return (
                <DKLabel
                  needIcon
                  text={`${obj.value}`}
                />
              );


            }, 
            searchableKey: 'value',
            style: {
              minWidth: 230
            },
          };
        }
        return element;
      });
      console.log("table converted array: ", tableConvertedArray);
      setColumnData(tableConvertedArray);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [tableId]);

  const fetchRecordData = useCallback(
    async (query, userFilters = currentFilters, sortCol, sortDir) => {
      console.log("tableID", tableId)
      setDropdownOptionsLoaded(false);
      const endpoint = `/app-builder/table/${tableId}/paged-record`;
      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        fetchAllRef: true,
        q: query,
        sortCol: sortCol,
        sortDir: sortDir,
      };

      if (query) {
        params.q = query;
      }

      const requestBody = {
        conditions: [],
        logicalOperator: userFilters.logicalOperator || "and",
      };
      if (userFilters.query.length > 0) {
        const conditions = userFilters.query.map((filter) => ({
          colId: filter.key,
          opr: filter.condition,
          value: filter.value,
        }));
        requestBody.conditions = conditions;
      }

      try {
        const response = await API.post(endpoint, requestBody, { params });
        console.log("fetched record data");
        const result = response.data;
        console.log("result",result.data);
        setDsrecordData(result);
        console.log("curr data", result.data)
        setRecordData(result.data.map((item) => item.cells));
        setTotalRecords(result.totalCount);
      } catch (error) {
        console.error("Failed to fetch record data:", error);
      }
    },
    [tableId, currentFilters, currentPage]
  );

  const handleSearch = useCallback(
    (searchText) => {
      setSearchQuery(searchText);
      const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
          const later = () => {
            clearTimeout(timeout);
            func(...args);
          };
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      };
      const debouncedSearch = debounce((query) => {
        fetchRecordData(query);
      }, 500);
      debouncedSearch(searchText);
    },
    [fetchRecordData]
  );

  const handleFilter = useCallback(
    (newFilterData) => {
      const updatedFilters = {
        ...currentFilters,
        logicalOperator:
          newFilterData.logicalOperator || currentFilters.logicalOperator,
        query: [
          ...currentFilters.query,
          ...newFilterData.query.filter(
            (filter) => !currentFilters.query.some((f) => f.key === filter.key)
          ),
        ],
      };

      setCurrentFilters(updatedFilters);
      // fetchRecordData(searchQuery, updatedFilters);
    },
    [currentFilters]
  );

  const handleSort = (data) => {
    const { order, columnData } = data;
    const sortDir = order === "ASC" ? 1 : -1;
    const sortCol = columnData.key;

    fetchRecordData(searchQuery, currentFilters, sortCol, sortDir);
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (data) {
      fetchRecordData(searchQuery);
    }
  }, [fetchRecordData, data, searchQuery]);

  const addColumn = async (data) => {
    try {
      const response = await API.post(
        `/app-builder/table/${tableId}/column`,
        data
      );
      console.log("Add Column Response", response.data);
      fetchData();
    } catch (error) {
      console.error("Failed to add column data:", error);
    }
  };

  const onColumnUpdate = async (data) => {
    try {
      const response = await API.put(
        `/app-builder/table/${tableId}/column/${data.columnData.id}`,
        data.columnData
      );
      console.log("Update Column Response", response.data);
      fetchData();
    } catch (error) {
      console.error("Failed to update column data:", error);
    }
  };

  const makeNameField = async (data) => {
    try {
      data.isNameField = true;
      const response = await API.put(
        `/app-builder/table/${tableId}/column/${data.id}`,
        data
      );
      console.log("Update Column Response", response.data);
      fetchData();
    } catch (error) {
      console.error("Failed to update column data:", error);
    }
  };

  const editRowData = async (data) => {
    const rcrd = dsrecordData.data[data.rowIndex];
    let dsRecordId = rcrd.dsRecordId;
    console.log("rcrd", data)
    const transformedRowData = Object.keys(data.rowData).reduce((acc, key) => {
      const column = columnData.find(col => col.id === key);
      if (column) {
        if (column.type === 'ref') {
          acc[key] = rcrd.cells[key]; 
        } else if (column.type === 'dropdown' && ([data.rowData[key]._id][0] != undefined)) {
            acc[key] = [data.rowData[key]._id];
            console.log("_id", acc[key])
        } else {
          acc[key] = data.rowData[key];
        }
      }
      return acc;
    }, {});
    console.log("transformedRowData ", transformedRowData)
    let reqdata = {
      dsRecordId: dsRecordId,
      cells: transformedRowData, // Use the transformed rowData
    };
    try {
      let response;
      if (rcrd._id) {
        response = await API.put(
          `/app-builder/table/${tableId}/record/${rcrd._id}`,
          reqdata
        );
      } else {
        response = await API.post(
          `/app-builder/table/${tableId}/record`,
          reqdata
        );
      }
      console.log("Edited Row Data", response.data);
    } catch (error) {
      console.error("Failed to edit row data:", error);
    }
    fetchRecordData();
  };
  const currentNameFieldColumnIndex = columnData.findIndex(
    (column) => column.id === data.nameField
  );
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // const handleOpenRefTabModal = () => setShowRefTabPopup(true);
  const handleOpenRefTabModal = () =>
    showAddColumnPopup(
      {
        tableName: data.name,
        columnDList: columnData,
        appId: appId,
        tableId: tableId,
      },
      (data) => {
        fetchData();
      },
      () => {}
    );
  // const handleCloseRefTabModal = () => setShowRefTabPopup(false);

  const handleSelectNameField = async (column) => {
    await makeNameField(column);
    setShowNameFieldModal(false);
  };
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <div className="main-main align-items-start">
      {showNameFieldModal && (
        <Popup onClose={() => setShowNameFieldModal(false)}>
          <DKInputForm
            title="Settings"
            fields={[
              {
                key: "name",
                placeholder: "Table Name",
                required: false,
                title: "Change Table Name",
                type: "text",
                value: data.name,
              },
              {
                key: "nameField",
                label: "NameField Column",
                type: "select",
                options: columnData.map((column) => column.name),
                placeholder: "Select a Column",
                required: false,
                title: "Set Name Field",
                value:
                  currentNameFieldColumnIndex >= 0
                    ? [currentNameFieldColumnIndex.toString()]
                    : [],
              },
            ]}
            onCancel={() => setShowNameFieldModal(false)}
            onSubmit={(formData) => {
              const tableNameField = formData.find(
                (field) => field.key === "name"
              );
              if (tableNameField.value && tableNameField.value.trim() !== "") {
                handleUpdateTableName(tableNameField.value.trim());
              }
              const nameFieldColumnIndex = formData.find(
                (field) => field.key === "nameField"
              );
              if (nameFieldColumnIndex.value) {
                const selectedColumn =
                  columnData[nameFieldColumnIndex.value[0]];
                if (selectedColumn) {
                  handleSelectNameField(selectedColumn);
                }
              } else {
                console.log("No change in nameField");
              }
              setShowNameFieldModal(false);
            }}
          />
          <div className="flex justify-end">
            <DKButton
              className="bg-red-500 text-white border-m"
              icon={DKIcons.white.ic_delete}
              onClick={() => {
                showAlert("Delete Table", "Are you sure?", [
                  {
                    title: "Cancel",
                    className: "bg-blue mr-r text-white",
                    onClick: () => {},
                  },
                  {
                    title: "Delete",
                    className: "bg-red text-white",

                    onClick: async () => {
                      try {
                        const response = await API.delete(
                          `/app-builder/table/${tableId}`
                        );
                        await navigate(`/app/${appId}`);
                        window.location.reload();

                        // fetchData();
                      } catch (error) {
                        console.error(
                          "Error while creating the application",
                          error
                        );
                      }
                    },
                  },
                ]);
              }}
            ></DKButton>
          </div>
        </Popup>
      )}

      <div className="main-page">
        <DKDataGrid
          allowBulkOperation
          allowColumnEdit
          allowColumnShift
          allowColumnSort
          allowColumnWebhook
          allowExpand
          allowFilter
          allowRowEdit
          allowColumnDelete
          allowSearch
          currentPage={currentPage}
          allowFormulaColumn={true}
          // onRowClick={(data) => alert(JSON.stringify(data))}
          onPageChange={(data) => setCurrentPage(data)}
          totalPageCount={totalPageCount}
          filterData={currentFilters.query}
          filterOperator={currentFilters.logicalOperator}
          onRowUpdate={(data) => editRowData(data)}
          onRowSelect={(data) => alert(JSON.stringify(data))}
          onColumnAdd={(data) => addColumn(data.columnData)}
          onColumnUpdate={(data) => onColumnUpdate(data)}
          onSearch={handleSearch}
          onFilter={handleFilter}
          onSort={(data) => handleSort(data)}
          buttons={[
            {
              className: "fs-r bg-blue text-white ml-r",
              onClick: handleOpenModal,
              title: "+ Add New Record",
            },
            {
              className: "fs-r bg-white ml-r border-m mr-r bg-white",
              onClick: () => setShowNameFieldModal(true),
              icon: DKIcons.ic_settings,
            },
            {
              className: "fs-r bg-white  ml-r border-m mr-r bg-white",
              onClick: handleOpenRefTabModal,
              title: "+ New Column",
            },
          ]}
          columns={columnData}
          rows={recordData}
          title={`${data ? `${data.name} (${totalRecords})` : "Loading..."}`}
        />
      </div>

      {showModal && (
        <Popup onClose={handleCloseModal}>
          <DKInputForm
            fields={formData}
            onCancel={handleCloseModal}
            onSubmit={async (data) => {
              // console.log(formData);
              console.log("formData:", data);

              function transformData(data) {
                return data.reduce(
                  (
                    newData,
                    { editable, options, required, title, type, key, value }
                  ) => {
                    if (type === "select" || type === "multi-select") {
                      if (!!options && options.length !== 0) {
                        const column = columnData.find((col) => col.id === key);
                        if (!!column) {
                          value = value
                            .filter((idx) => !!column.options[idx])
                            .map((idx) => column.options[idx].id);
                          newData[key] = value;
                        }
                      }
                    } else {
                      newData[key] = value;
                    }
                    return newData;
                  },
                  {}
                );
              }

              const transformedData = transformData(data);

              Object.keys(transformedData).forEach((key) => {
                if (transformedData[key] === undefined) {
                  delete transformedData[key];
                }
              });

              console.log("transformed data", transformedData);
              console.log("td key length", Object.keys(transformedData).length);
              if (Object.keys(transformedData).length > 0) {
                const finalData = { cells: transformedData };

                console.log("Final Data ", finalData);

                const response = await API.post(
                  `/app-builder/table/${tableId}/record`,
                  finalData
                );
                console.log("save button click response", response);
                if (response.data) {
                  fetchRecordData();
                  setFormData([]);
                  console.log("Successfully saved the data", response.data);
                } else {
                  console.log("Failed to save the data", response.data);
                }

                handleCloseModal(); // Assuming you handle form submission and state update here
              } else {
                showToast("Fill atleast one");
              }
            }}
            title="Add New Record"
          />
        </Popup>
      )}
    </div>
  );
}

export default TableIdDisplay;
