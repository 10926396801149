import {DOC_TYPE} from "../../constants/Constant";
import Utility from "../../utility/Utility";


export const SHOW_TOTAL_IN_BASE_CURRENCY = 'showTotalInBaseCurrency';
export const FILTER_DATE = 'filterDate';

export enum MODULE_NAME_FOR_STORAGE_UTILITY {
    SELL = 'SELL',
    BUY = 'BUY'
}

export const defaultSellConfigSettings = {
    [DOC_TYPE.QUOTE]: {
        showTotalInBaseCurrency: false,
        filterDate: {
            start: new Date(),
            end: new Date()
        }
    },
}

export const defaultBuyConfigSettings = {
    [DOC_TYPE.INVOICE]: {
        showTotalInBaseCurrency: false,
        filterDate: {
            start: new Date(),
            end: new Date()
        }
    }
}

export class SessionStorageService {
    static saveConfigToStorage(
        moduleName: MODULE_NAME_FOR_STORAGE_UTILITY,
        documentType: DOC_TYPE,
        config: any
    ) {
        const sessionStorage = window.sessionStorage;
        let updatedSessionStorage = JSON.parse(
            sessionStorage.getItem(moduleName) as string
        );

        // if (!Utility.isEmptyObject(config) && FILTER_DATE in config) {
        //   updatedSessionStorage[documentType][FILTER_DATE] = config[FILTER_DATE];
        // }

        if (!Utility.isEmptyObject(config) && SHOW_TOTAL_IN_BASE_CURRENCY in config) {
            updatedSessionStorage[documentType][SHOW_TOTAL_IN_BASE_CURRENCY] =
                config[SHOW_TOTAL_IN_BASE_CURRENCY];
        }

        sessionStorage.setItem(moduleName, JSON.stringify(updatedSessionStorage));
    }

    static fetchConfigSettings(
        moduleName: MODULE_NAME_FOR_STORAGE_UTILITY,
        documentType: DOC_TYPE
    ) {
        const moduleData = window.sessionStorage.getItem(moduleName);
        if (moduleData) {
            const parsedModuleSetting = JSON.parse(moduleData);
            if (parsedModuleSetting[documentType]) {
                return parsedModuleSetting[documentType];
            } else {
                return SessionStorageService.createConfigForModule(
                    moduleName,
                    documentType
                );
            }
        } else {
            return SessionStorageService.createConfigForModule(
                moduleName,
                documentType
            );
        }
    }

    static createConfigForModule(
        moduleName: MODULE_NAME_FOR_STORAGE_UTILITY,
        documentType: DOC_TYPE
    ) {
        const setSessionStorage = window.sessionStorage;
        let defaultConfig: any;
        if (moduleName === MODULE_NAME_FOR_STORAGE_UTILITY.SELL) {
            defaultConfig = defaultSellConfigSettings;
        } else {
            defaultConfig = defaultBuyConfigSettings;
        }
        // for (const [key, value] of Object.entries(defaultConfig)) {
        //   if (!Utility.isEmpty(defaultConfig[key].filterDate)) {
        //     defaultConfig[key].filterDate.start = Utility.getFinancialStartDate();
        //     defaultConfig[key].filterDate.end = Utility.getFinancialEndDate();
        //   }
        // }
        setSessionStorage.setItem(moduleName, JSON.stringify(defaultConfig));
        return defaultConfig[documentType];
    }

    static deleteSessionStorage() {
        window.sessionStorage.clear();
        localStorage.removeItem('isBookKepper');
    }
}
