import DataParser from "../Helper/DataParser";
// import { readAndCompressImage } from "browser-image-resizer";
import {DKIcons, showAlert, showToast, TOAST_TYPE} from "deskera-ui-library";
import TenantManager from "../managers/TenantManager";

import ic_attachment from "../assets/icons/icon_attachment.png";
import {
    CHAR_ENTITY_MAP,
    FORBID_ATTR,
    FORBID_TAGS,
    HTML_SPECIAL_CHAR,
    MONTHS,
    NUMBER_FORMAT,
    PRODUCT_TYPE,
    REGEX,
    UOM_NA_ID,
    YT_THUMBNAIL_QUALITIES
} from "../constants/Constant";
import RouteManager from "../managers/RouteManager";
import NumberFormatService from "../services/numberFormat";
import {sanitize} from "dompurify";
import ApiConstants from "../constants/ApiConstants";

export function getVW(value) {
    //   return value / PIXEL_TO_VW + "vw";
}

export function sanitizeHTML(html) {
    return sanitize(html, {
        FORBID_TAGS: FORBID_TAGS,
        FORBID_ATTR: FORBID_ATTR
    });
}

export function getCapitalized(name) {
    let str = name;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isData(data) {
    return data !== null && data !== undefined;
}

export function isUndefined(value) {
    return typeof value === "undefined";
}

export function toCurrencyFormat(number, currencySymbol = null, decimalScale = 2) {
    if (Utility.isEmptyObject(currencySymbol))
        currencySymbol = TenantManager.getCRMCurrencySymbol();
    return (
        currencySymbol +
        " " +
        parseFloat("" + number)
            .toFixed(decimalScale)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
}

export function toKFormat(num) {
    return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
        : Math.sign(num) * Math.abs(num);
}

export function getRandomAlphaNumericString(length = 16) {
    const chars =
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function getRandomHexString(length = 24) {
    const chars = "0123456789abcdef";
    let result = "";
    for (let i = length; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function getRandomNumber(number = 1000) {
    return Math.floor(Math.random() * number);
}

///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////

export function swapArrayElement(arr, fromIndex, toIndex) {
    let b = arr[fromIndex];
    arr[fromIndex] = arr[toIndex];
    arr[toIndex] = b;
    return arr;
}

export function shiftArrayElement(arr, fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr;
}

export function sortArray(arr, sortOrder, keyToSort) {
    return arr.sort(function (obj1, obj2) {
        const i1 = sortOrder === "ASC" ? obj1[keyToSort] : obj2[keyToSort];
        const i2 = sortOrder === "ASC" ? obj2[keyToSort] : obj1[keyToSort];
        if (!isNaN(i1)) {
            return Number(i1) - Number(i2);
        }

        if (i1 < i2) {
            return -1;
        }
        if (i1 > i2) {
            return 1;
        }
        return 0;
    });
}

export function isMacintosh() {
    let deviceDetect = navigator.platform;
    let appleDevicesArr = [
        "MacIntel",
        "MacPPC",
        "Mac68K",
        "Macintosh",
        "iPhone",
        "iPod",
        "iPad",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad Simulator",
        "Pike v7.6 release 92",
        "Pike v7.8 release 517"
    ];

    if (appleDevicesArr.includes(deviceDetect)) {
        return true;
    }

    return false;
}

export const parseJWTToken = (token) => {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join("")
    );

    return JSON.parse(jsonPayload);
};

export function sanitizeJSON(jsonString) {
    return jsonString.replace(REGEX.HTML_PATTERN, "");
}

export function sanitizeHtmlInput(text) {
    return String(text).replace(REGEX.SANITIZE_HTML_CHAR, function (s) {
        return CHAR_ENTITY_MAP[s] || "";
    });
}

export default class Utility {
    static encodeString(json) {
        let temp = encodeURI(json);
        return btoa(temp);
    }

    static decodeString(base64String) {
        return JSON.parse(atob(base64String));
    }

    ////////////////////////////////////////////////
    static encodeStringForTemplate(json) {
        return btoa(unescape(encodeURIComponent(json)));
    }

    static decodeStringForTemplate(base64String) {
        return decodeURIComponent(escape(atob(base64String)));
    }

    ////////////////////////////////////////////////

    ///////////////////// JSON encryption ///////////////////////////
    static encodeJSON(json) {
        let temp = encodeURI(JSON.stringify(json));
        return btoa(temp);
    }

    static decodeJSON(base64String) {
        return JSON.parse(decodeURI(atob(base64String)));
    }

    ////////////////// END - JSON encryption /////////////////////////

    ///////////////////// HTML encryption ///////////////////////////
    static encodeHTML(html) {
        let temp = encodeURI(html);
        return btoa(temp);
    }

    static decodeHTML(base64String) {
        return decodeURI(atob(base64String));
    }

    ////////////////// END - HTML encryption /////////////////////////

    static copyToClipBoard(textToCopy, needToast = false) {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy).then(() => {
                if (needToast) {
                    showToast("Link Copied!!!", TOAST_TYPE.SUCCESS);
                }
            });
        }
    }

    static isDevice() {
        if (window.screen.width <= 1142) {
            return true;
        }
        return false;
    }

    static openInNewTab = (url) => {
        window.open(url, "_blank");
    };

    static isValidNumber(number) {
        return !isNaN(number) && !isNaN(parseFloat(number));
    }

    static isValidEmail(email) {
        if (!this.isEmptyObject(email)) {
            const re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
        return false;
    }

    static sanitizePhoneForWhatsapp(phone) {
        if (!this.isEmptyObject(phone)) {
            phone = phone.replaceAll(REGEX.WHATSAPP, "");
        }
        return phone;
    }

    static isValidURL(string) {
        let res = string.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res !== null;
    }

    static isValidDate(string) {
        const date = new Date(string);
        return Boolean(date.getTime());
    }

    ////////////////////////////////////////////////////////
    //////////// START - LOCAL STORAGE FEATURES ////////////
    ////////////////////////////////////////////////////////

    static setPersistentValue(key, value) {
        localStorage.setItem(key, value);
    }

    static getPersistentValue(key) {
        return localStorage.getItem(key);
    }

    static setPersistentSessionValue(key, value) {
        sessionStorage.setItem(key, value);
    }

    static getPersistentSessionValue(key) {
        return sessionStorage.getItem(key);
    }

    static getURLOptionValue(urlOption) {
        return urlOption === undefined || urlOption === null
            ? false
            : urlOption.toLowerCase() === "true"
                ? true
                : false;
    }

    /**
     @deprecated - use isEmptyObject instead
     */
    static isEmpty = (value) => {
        if (value === null || value === undefined || value.length === 0)
            return true;
        if (Array.isArray(value) || typeof value === "string") return !value.length;
        return Object.keys(value).length === 0;
    };
    /**
     *
     * @param {*} objectToCheck - object to check (string, number, array, object)
     * @returns boolean - true if object is empty else false
     */
    static isEmptyObject = (objectToCheck) => {
        if (
            objectToCheck === null ||
            objectToCheck === undefined ||
            objectToCheck.length === 0
        )
            return true;
        if (Array.isArray(objectToCheck)) return !objectToCheck.length;
        if (Utility.isValidNumber(objectToCheck)) return !Number(objectToCheck);
        if (typeof objectToCheck === "string") return !objectToCheck.trim().length;
        return Object.keys(objectToCheck).length === 0;
    };

    static validateAgainstPattern(string, pattern) {
        return pattern.test(string);
    }

    static getFirstAndLastDayByMonth(
        month = new Date().getMonth(),
        year = new Date().getFullYear()
    ) {
        return [
            DataParser.formatDate(new Date(year, month, 1)),
            DataParser.formatDate(new Date(year, month + 1, 0))
        ];
    }

    static makeCopyOfObject(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    /**
     *
     * @param {any} object
     * @returns - a deep copy of the object
     */
    static deepCloneObject = (object) => {
        let deepCopy = object;
        if (!Utility.isEmptyObject(object)) {
            deepCopy = Utility.makeCopyOfObject(object);
        }
        return deepCopy;
    };

    static getFormattedTime(date, needSeconds = false) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let seconds = date.getSeconds();
        let x = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        let time = `${hours}:${minutes}${needSeconds ? ":" + seconds : ""} ${x}`;
        return time;
    }

    static getQueryParam(location, param) {
        if (location?.search && param) {
            const query = new URLSearchParams(location.search);
            return query.get(param);
        }

        return null;
    }

    static highlightString(messageText, searchText) {
        const SPECIAL_CHAR_RE = /([.?*+^$[\]\\(){}|-])/g;
        const escapedSearch = searchText.replace(SPECIAL_CHAR_RE, "\\$1");
        let message = messageText.replace(
            new RegExp(`${escapedSearch}`, "i"),
            (match) =>
                `<span style="background: yellow; color: black;">${match}</span>`
        );
        return message;
    }

    static getFileIcon = (fileName) => {
        let fileType = fileName?.split(".");
        switch (fileType.pop()) {
            case "txt":
                return DKIcons.doc_type.ic_txt;

            case "pdf":
                return DKIcons.doc_type.ic_pdf;

            case "jpg":
            case "jpeg":
                return DKIcons.doc_type.ic_jpg;
            case "png":
            case "svg":
            case "bmp":
                return DKIcons.doc_type.ic_png;

            case "doc":
            case "docx":
                return DKIcons.doc_type.ic_doc;

            case "xlsx":
            case "xls":
                return DKIcons.doc_type.ic_xls;
            case "csv":
                return DKIcons.doc_type.ic_csv;

            case "pptx":
            case "ppt":
                return DKIcons.doc_type.ic_ppt;
            default:
                return ic_attachment;
        }
    };

    static setCookie(name, value, days, domain) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        if (domain) {
            domain = "; domain=" + domain;
        }
        document.cookie = name + "=" + value + expires + domain + "; path=/";
    }

    static getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    static eraseCookie(name) {
        document.cookie =
            name + "=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    static decodeBase64Uri = (encodedData) => {
        try {
            return decodeURIComponent(
                Buffer.from(encodedData, "base64").toString("ascii")
            );
        } catch (error) {
            return encodedData;
        }
    };

    static encodeBase64Uri = (decodedData) => {
        try {
            return Buffer.from(encodeURIComponent(decodedData), "ascii").toString(
                "base64"
            );
        } catch (error) {
            return decodedData;
        }
    };

    static isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    ///////////////////////Mobile App Only////////////////////////////
    static postMobileAppActions(action) {
        window.ReactNativeWebView.postMessage(action);
    }

    /////////////////////////////////////////////////////////////////

    static alertWithRedirection = (alertType, alertMessage, redirectUrl) => {
        const buttons = [
            {
                title: "Ok",
                className: "bg-button text-white ml-r",
                onClick: () => {
                    RouteManager.navigateToPage(redirectUrl);
                }
            }
        ];
        showAlert(alertType, alertMessage, buttons);
    };

    static getFormattedDate(myDate) {
        let abbrMonths = MONTHS;
        return (
            myDate.getDate() +
            " " +
            abbrMonths[myDate.getMonth()] +
            " " +
            myDate.getFullYear()
        );
    }

    static getNonPendingsItems(documentItems) {
        return (
            documentItems &&
            documentItems.filter((item) => {
                const isNonTrackedNA = Utility.isNonTrackedNA(item.product);
                return !isNonTrackedNA && item.pendingQuantity <= 0;
            })
        );
    }

    static isNonTrackedNA(product) {
        return (
            product &&
            product.type === PRODUCT_TYPE.NON_TRACKED &&
            product.stockUom === UOM_NA_ID
        );
    }

    static roundingOff(val, precisionVal = 6) {
        val = Number(val);
        val = val + 1 / Math.pow(10, precisionVal + 10);
        var newnumber =
            Math.round(val * Math.pow(10, precisionVal)) / Math.pow(10, precisionVal);
        return newnumber;
    }

    static roundOff(val, decimalScale = 2) {
        return Utility.roundingOff(val, decimalScale);
    }

    static padLeadingZeros(number, size) {
        var value = number + "";
        while (value.length < size) value = "0" + value;
        return value;
    }

    static convertInTitleCase(str) {
        if (
            str === undefined ||
            str === null ||
            typeof str === undefined ||
            str === ''
        )
            return '';
        else str = str.toString();

        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    static getQueryString(queries) {
        const params = queries
            .map((i) =>
                Object.entries(i).map(([key, val]) => {
                    const k = key;
                    const v = val;
                    if (k === 'customfield') {
                        const value = JSON.stringify(v);

                        return `${k}=${encodeURI(value)}`;
                    } else {
                        if (typeof v !== 'object') {
                            let conditionSign = '=';
                            switch (v?.condition) {
                                case 'eq':
                                    conditionSign = '=';
                                    break;
                                case 'gt':
                                    conditionSign = '>=';
                                    break;
                                case 'lt':
                                    conditionSign = '<=';
                                    break;
                                case 'apx':
                                    conditionSign = '~';
                                    break;
                                default:
                                    break;
                            }
                            if (JSON.stringify(k) && JSON.stringify(v)) {
                                return `${k}${conditionSign}${v?.value ? v?.value : v}`;
                            }
                            return '';
                        } else {
                            if (JSON.stringify(k) && JSON.stringify(v)) {
                                if (typeof v !== 'object') {
                                    return `${k}=${v}`;
                                } else {
                                    let conditionSign = '=';
                                    switch (v?.condition) {
                                        case 'eq':
                                            conditionSign = '=';
                                            break;
                                        case 'gt':
                                            conditionSign = '>=';
                                            break;
                                        case 'lt':
                                            conditionSign = '<=';
                                            break;
                                        case 'apx':
                                            conditionSign = '~';
                                            break;
                                        default:
                                            break;
                                    }
                                    return `${k}${conditionSign}${v?.value}`;
                                }
                            }
                        }
                        return '';
                    }
                })
            )
            .join(',');
        return params;
    }
}
export const numberWithCommas = (x) => {
    return ("" + x).replace(
        /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
        function (m, s1, s2) {
            return s2 || s1 + ",";
        }
    );
};
export const getValue = (...values) => {
    return values.find((v) => !!v);
};
export const wholeNumberWithCommas = (x) => {
    if (x === undefined) {
        return "";
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const isString = (value) => {
    return (
        typeof value === "string" ||
        value instanceof String ||
        Object.prototype.toString.call(value) === "[object String]"
    );
};
export const paramsSerializer = (key, params) => {
    let paramString = "";
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            paramString += `${key}=${params[key]}&`;
        }
    }
    return key + "=" + paramString.substring(0, paramString.lastIndexOf("&"));
};
export const isObject = (object) =>
    Object.prototype.toString.call(object) === "[object Object]";

export const convertDateToAgoString = (date) => {
    const duration = new Date() - new Date(date);
    const hours = Math.round(duration / 3600 / 1000);
    const days = Math.round(hours / 24);
    return hours === 0 || isNaN(hours)
        ? "Just created"
        : hours > 24
            ? days > 1
                ? parseInt(days) + " days ago"
                : parseInt(days) + " day ago"
            : parseInt(hours) + " hrs ago";
};

export const trimString = (string, trimLength = 100) => {
    return string.length > trimLength
        ? string.substring(0, trimLength) + "..."
        : string;
};

export const unEscapeHtml = (htmlStr) => {
    htmlStr = htmlStr.replace(/&lt;/g, "<");
    htmlStr = htmlStr.replace(/&gt;/g, ">");
    htmlStr = htmlStr.replace(/&quot;/g, '"');
    htmlStr = htmlStr.replace(/&#39;/g, "'");
    htmlStr = htmlStr.replace(/&amp;/g, "&");
    return htmlStr;
};

export const findAndConvertLink = (text) => {
    return text.replace(REGEX.TEXT_TO_LINK, function (url) {
        var hyperlink = url;
        if (!hyperlink.match("^https?://")) {
            hyperlink = "http://" + hyperlink;
        }
        return (
            '<a className="text-blue text-underline cursor-hand" href="' +
            hyperlink +
            '" rel="noreferrer" target="_blank">' +
            url +
            "</a>"
        );
    });
};
export const getYoutubeVideoThumbnailLink = (
    videoId,
    quality = YT_THUMBNAIL_QUALITIES.default
) => {
    return `https://img.youtube.com/vi/${videoId}/${quality}.jpg`;
};

export function youtubeVideoIDParser(url) {
    let match = url.match(REGEX.YOUTUBE_VIDEO);
    return match && match[7].length == 11 ? match[7] : false;
}

export const unitPriceRenderer = (price) => {
    return NumberFormatService.getNumber(price);
};
export const getLocaleFromTenantNumberFormat = (numberFormat) => {
    const numberFormatMap = {
        [NUMBER_FORMAT.ES]: "es",
        [NUMBER_FORMAT.FR]: "fr",
        [NUMBER_FORMAT.IN]: "en-IN",
        [NUMBER_FORMAT.US]: "en-US"
    };
    return numberFormatMap[numberFormat];
};
export const replaceHTMLSpecialChars = (stringToCheck) => {
    Object.keys(HTML_SPECIAL_CHAR).forEach((key) => {
        stringToCheck = stringToCheck?.replaceAll(HTML_SPECIAL_CHAR[key], key);
    });
    return stringToCheck;
};
export const removeHtmlTagsAndNormalizeSpaces = (input) => {
    // Use a regular expression to match HTML tags and replace them with a space
    let result = input.replace(/<\/?[^>]+(>|$)/g, " ");

    // Use another regular expression to replace multiple spaces with a single space
    result = result.replace(/\s+/g, " ");

    // return result.trim(); // Optional: Trim leading and trailing spaces
    return result.trim();
};

export const redirectToBooksPlus = () => {
    let openDocument = `${ApiConstants.PRODUCT_URL_BOOKS_PLUS}${ApiConstants.URL.BOOKS.PRODUCT}`;
    window.open(openDocument, '_blank');
};