import React, {Component} from "react";

class Empty extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div/>;
    }
}

export default Empty;
