// applicationSlice.ts

import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import API from "../../components/api";

interface ApplicationState {
    applications: any[];
    isLoading: boolean;
    error: string;
    searchTerm: string;
}

const initialState: ApplicationState = {
    applications: [],
    isLoading: false,
    error: "",
    searchTerm: "",
};

export const fetchApplications = createAsyncThunk(
    "applications/fetchApplications",
    async (searchTerm: string) => {
        const response = await API.get(`/app-builder/app?q=${searchTerm}`);
        console.log("response.data.data", response.data.data);
        return response.data.data;
    }
);

export const renameApplication = createAsyncThunk(
    "applications/renameApplication",
    async ({appId, newName}: { appId: string; newName: string }) => {
        const response = await API.put(`/app-builder/app/${appId}`, {
            name: newName,
        });
        console.log("newName", newName);
        return response.data;
    }
);

export const deleteApplication = createAsyncThunk(
    "applications/deleteApplication",
    async (appId: string) => {
        const response = await API.delete(`/app-builder/app/${appId}`);
        return response.data;
    }
);

const applicationSlice = createSlice({
    name: "applications",
    initialState,
    reducers: {
        setSearchTerm: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchApplications.fulfilled, (state, action) => {
            state.applications = action.payload;
            state.error = "";
            state.isLoading = false;
        });
        builder.addCase(fetchApplications.rejected, (state) => {
            state.error = "Failed to load applications";
            state.isLoading = false;
        });
        builder.addCase(renameApplication.fulfilled, (state) => {
            // Handle success if needed
        });
        builder.addCase(deleteApplication.fulfilled, (state) => {
            // Handle success if needed
        });
    },
});

export const {setSearchTerm} = applicationSlice.actions;

export default applicationSlice.reducer;
