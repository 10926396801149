import React from "react";
import {Outlet} from "react-router-dom";
import AppManager from "../../managers/AppManager";
import LeftMenu from "../menu/LeftMenu";
import SideBarService from "../../services/sidebar";
import TopMenu from "../menu/TopMenu";

const AdminDashboard = ({didInitialApiResponseReceived}) => {
    return (
        <div className="main-holder bg-gray1 row align-items-start app-font ">
            {didInitialApiResponseReceived &&
                !AppManager.isPublicURL() &&
                !AppManager.isBookADemoURL() && <LeftMenu/>}

            <div
                className="parent-width parent-height column"
                style={{
                    flex: 1,
                    maxWidth: SideBarService.getContainerWidth(true),
                }}
            >
                {didInitialApiResponseReceived &&
                    !AppManager.isPublicURL() &&
                    !AppManager.isBookADemoURL() && <TopMenu/>}

                <div className={"overflow-y-auto w-[100%]"}>
                    <Outlet/>
                </div>

            </div>
        </div>
    );
};

export default AdminDashboard;
