import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import User from "../../services/user";
import IUser from "../../model/User";
import {RootState} from "../store";
import {createSelector} from "reselect";
import Tenant from "../../services/tenant";
import ICurrency from "../../model/Currency";
import NumberFormatService from "../../services/numberFormat";


export interface TenantState {
    tenants: any[];
    usersInfo: any;
    users: IUser[];
    currencies: ICurrency[];
    tenantsInfo: any;
    tenantsDetails: any;
    hasTenantFinanceDetails: boolean,

}

const initialState: TenantState = {
    tenants: [],
    users: [],
    usersInfo: {},
    hasTenantFinanceDetails: false,
    currencies: [],
    tenantsInfo: {},
    tenantsDetails: {},
};


export const fetchTenantsInfo = createAsyncThunk("tenants/fetchTenantsInfo", async (data: any, thunkAPI) => {
    const response: any = await Tenant.fetchTenantsInfo(data);
    return response;
});
export const fetchTenantDetails = createAsyncThunk("tenants/fetchTenantDetails", async (data: any, thunkAPI) => {
    const response: any = await Tenant.getTenantDetails();
    NumberFormatService.setNumberFormatter(
        response.numberFormat,
        response.decimalScale
    );
    return response;
});


export const fetchTenants = createAsyncThunk("tenants/fetchTenants", async (data, thunkAPI) => {
    const response = await Tenant.getAllTenants();
    return response;
});

export const fetchUsers = createAsyncThunk("tenants/fetchUsers", async (data, thunkAPI) => {
    const response = await User.getUsers();
    return response;
});

export const fetchCurrencies = createAsyncThunk("tenants/fetchCurrencies", async (data, thunkAPI) => {
    const response = await Tenant.getCurrencyEnum();
    return response;
});
export const fetchUserInfo = createAsyncThunk("tenants/fetchUserInfo", async (data: any, thunkAPI) => {
    const response = await User.fetchUsersInfo(data.params);
    return response;
});
export const tenantsSlice = createSlice({
    name: "tenant",
    initialState,
    reducers: {

        setTenantFinanceDetails: (state, action: PayloadAction<any>) => {
            // @ts-ignore
            state.hasTenantFinanceDetails = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder.addCase(fetchTenants.fulfilled, (state, action) => {
            state.tenants = action.payload as any;
        });
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            const res: any = action.payload;
            const filteredUsers = res.invites.filter(onlyUniqueUsers).map((user: { iamUserId: any; id: any; }) => {
                return {...user, id: user.iamUserId || user.id};
            });

            // TableDataParser.putDataInOwnersColumn(filteredUsers);

            state.users = filteredUsers;
        });
        builder.addCase(fetchTenantDetails.fulfilled, (state, action) => {
            state.tenantsDetails = action.payload;
        });
        builder.addCase(fetchCurrencies.fulfilled, (state, action: any) => {
            state.currencies = action.payload.content;
        });
        builder.addCase(fetchCurrencies.rejected, (state, action: any) => {
            state.currencies = [];
        });
        builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
            state.usersInfo = action.payload;
        });
    }
});

export const {setTenantFinanceDetails} = tenantsSlice.actions;

const onlyUniqueUsers = (item: IUser, index: number, users: []) => users.findIndex((obj: IUser) => obj.email === item.email) === index;

const selfSelector = (state: RootState) => state?.tenant;
export const getTenants = () => createSelector(selfSelector, (state: TenantState) => state.tenants);
export const getUsers = () => createSelector(selfSelector, (state: TenantState) => state.users);
export const getCurrencies = () => createSelector(selfSelector, (state: TenantState) => state.currencies);

export const selectUserInfo = (state: {
    tenant: { usersInfo: { content: any[]; }; };
}) => state.tenant?.usersInfo?.content?.[0];
export const isEnableAccountLinking = (state: {
    tenant: { crmSettings: { isDealRequiresAccount: any; }; };
}) => state.tenant?.crmSettings?.isDealRequiresAccount ?? false;
export default tenantsSlice.reducer;
