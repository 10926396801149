// ApplicationCard.js
import React, { useState } from 'react';
import { DKIcons, DKListPicker } from "deskera-ui-library";

const ApplicationCard = ({ app, onCardClick, handleRename, handleDelete }) => {
    const [showOptions, setShowOptions] = useState(false);

    const getInitials = (name) => {
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase().substring(0,4);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const openOptions = (e) => {
        e.stopPropagation();
        setShowOptions(true);
    };

    const closeOptions = () => {
        setShowOptions(false);
    };

    const onSelectOption = (option) => {
        closeOptions();
        if (option === "Rename") {
            handleRename(app._id);
        } else if (option === "Delete") {
            handleDelete(app._id);
        }
    };
    return (
        <div
            className="cursor-pointer rounded-lg shadow-md bg-white relative overflow-hidden hover:shadow-lg transition-shadow duration-300 m-4"
            onClick={() => onCardClick(app._id)}
            style={{padding:'2px', fontSize: '16px'}} // Responsive font size
        >
            <div className="absolute top-0 left-0 right-0 bg-blue-300 rounded-t-lg"
                 style={{height: '40%', opacity: 0.5}}>

                <button
                    onClick={openOptions}
                    className="absolute top-1/3 right-3 transform -translate-y-1/2 z-10 "
                    style={{outline: 'none', backgroundColor: 'transparent'}}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="#002CFFFF" stroke="#002CFFFF" strokeWidth="1.5px"
                         strokeLinecap="round" strokeLinejoin="round">
                        <circle cx="12" cy="12" r="2"></circle>
                        <circle cx="19" cy="12" r="2"></circle>
                        <circle cx="5" cy="12" r="2"></circle>
                    </svg>
                </button>
            </div>

            <div className="flex justify-center mt-8">
                <div
                    className="bg-blue-500 h-12 w-12 rounded-full text-white flex items-center justify-center text-md font-semibold z-index-2">
                    {getInitials(app.name)}
                </div>
            </div>

            <div className="text-center mt-3 pb-4">
                <h3 className="text-xl font-semibold mb-2">{app.name.toLowerCase()}</h3>
                <p className="text-gray-400 text-[14px]">{`Created on ${formatDate(app.createdAt)}`}</p>
            </div>
            <div className={"z-index-8"} onClick={(e) => {
                e.stopPropagation()
            }}>
                {showOptions && (
                    <DKListPicker
                        data={["Rename", "Delete"]}
                        onSelect={(index, value) => onSelectOption(value)}
                        onClose={closeOptions}
                        style={{
                            position: 'absolute',
                            right: '5px',
                            top: '30px',
                            zIndex:"4"
                        }}
                        icons={[DKIcons.ic_edit, DKIcons.ic_delete]}
                    />
                )}
            </div>

        </div>
    );
};


export default ApplicationCard;
