import React, {useState} from 'react';
import {DKDataGrid, DKIcons, DKLabel} from 'deskera-ui-library';
// Sample menu items
const menuItemList = [
    {
        name: 'Dashboard',
        icon: DKIcons.dashboardIcon,
        route: '/dashboard',
        onClick: () => console.log('Dashboard clicked'),
    },
    {
        name: 'Settings',
        icon: DKIcons.settingsIcon,
        route: '/settings',
        subItems: [
            {name: 'Profile', route: '/settings/profile'},
            {name: 'Account', route: '/settings/account'},
        ],
    },
];

// Sample tenant details
const tenantList = [
    {tenantName: 'Tenant 1', tenantId: 1},
    {tenantName: 'Tenant 2', tenantId: 2},
];

const Test = () => {
    const [isMenuExpanded, setIsMenuExpanded] = useState(true);
    const [selectedTenant, setSelectedTenant] = useState(tenantList[0]);

    const handleTenantSelect = (tenant) => {
        console.log('Tenant selected:', tenant);
        setSelectedTenant(tenant);
    };

    const handleExpandCollapse = () => {
        setIsMenuExpanded(!isMenuExpanded);
    };

    return (
        <DKDataGrid
            allowBottomRowAdd
            allowBulkOperation
            allowColumnAdd
            allowColumnDelete
            allowColumnEdit
            allowColumnShift
            allowColumnSort
            allowColumnWebhook
            allowExpand
            allowFilter
            allowRowEdit
            allowSearch
            allowShare
            buttons={[
                {
                    className: 'fs-r bg-blue text-white ml-r',
                    onClick: () => {
                    },
                    title: '+ Add New'
                }
            ]}
            columns={[
                {
                    allowFilter: false,
                    dropdownConfig: {
                        allowSearch: true,
                        button: {
                            className: 'bg-blue text-white',
                            onClick: function noRefCheck() {
                            },
                            title: '+ Add New'
                        },
                        className: 'shadow-m',
                        data: [
                            {
                                age: 23,
                                title: 'Alex Wheels'
                            },
                            {
                                age: 43,
                                title: 'Sam Muller'
                            }
                        ],
                        onSelect: function noRefCheck() {
                        },
                        renderer: function noRefCheck() {
                        },
                        searchableKey: 'title',
                        style: {
                            minWidth: 230
                        },
                        title: 'Select User'
                    },
                    editable: true,
                    formatter: function noRefCheck() {
                    },
                    hidden: false,
                    id: '111',
                    key: 'user',
                    name: 'Drop Down Type',
                    systemField: true,
                    type: 'dropdown',
                    uiVisible: true,
                    width: 200
                },
                {
                    editable: true,
                    hidden: false,
                    id: '2',
                    key: 'profile',
                    name: 'Text Type',
                    systemField: true,
                    type: 'text',
                    uiVisible: true,
                    width: 200
                },
                {
                    editable: true,
                    hidden: false,
                    id: '3',
                    key: 'age',
                    name: 'Number',
                    systemField: true,
                    textAlign: 'right',
                    type: 'number',
                    uiVisible: true,
                    width: 130
                },
                {
                    dropdownConfig: {
                        data: [
                            2300,
                            500,
                            30000
                        ],
                        renderer: function noRefCheck() {
                        },
                        title: 'Compensation History'
                    },
                    editable: true,
                    formatter: function noRefCheck() {
                    },
                    hidden: false,
                    id: '4',
                    key: 'compensation',
                    name: 'Number with Formatter and DropDown',
                    systemField: true,
                    type: 'number',
                    uiVisible: true,
                    width: 220
                },
                {
                    dropdownConfig: {
                        allowSearch: true,
                        button: {
                            className: 'bg-blue text-white',
                            onClick: function noRefCheck() {
                            },
                            title: '+ Add New'
                        },
                        data: [
                            {
                                id: '0001',
                                name: 'Tax 1'
                            },
                            {
                                id: '0002',
                                name: 'Tax 2'
                            },
                            {
                                id: '0003',
                                name: 'Tax 3'
                            },
                            {
                                id: '0004',
                                name: 'School Bag'
                            },
                            {
                                id: '0005',
                                name: 'Tax 4'
                            },
                            {
                                id: '0006',
                                name: 'Tax 5'
                            },
                            {
                                id: '0007',
                                name: 'Tax 6'
                            },
                            {
                                id: '0008',
                                name: 'Tax 7'
                            }
                        ],
                        onSelect: function noRefCheck() {
                        },
                        renderer: function noRefCheck() {
                        },
                        searchableKey: 'name',
                        title: 'Select Tax'
                    },
                    editable: true,
                    formatter: function noRefCheck() {
                    },
                    hidden: false,
                    id: '6',
                    key: 'tax',
                    name: 'DropDown with formatter',
                    systemField: false,
                    type: 'dropdown',
                    uiVisible: true,
                    width: 220
                },
                {
                    editable: true,
                    hidden: false,
                    id: '7',
                    key: 'dob',
                    name: 'Date Field',
                    systemField: true,
                    type: 'date',
                    uiVisible: true,
                    width: 160
                },
                {
                    allowAddOption: true,
                    disableWebhook: true,
                    id: '8',
                    key: 'options',
                    name: 'Select Column',
                    options: [
                        {
                            color: 'data-grid-badge-color-4',
                            id: 1,
                            name: 'Option1',
                            systemField: true
                        },
                        {
                            color: 'data-grid-badge-color-6',
                            id: 2,
                            name: 'Option2'
                        }
                    ],
                    required: false,
                    type: 'select',
                    width: 150
                },
                {
                    allowAddOption: true,
                    disableWebhook: true,
                    id: '10',
                    key: 'multioption',
                    name: 'Multi-Select Column',
                    options: [
                        {
                            color: 'data-grid-badge-color-4',
                            id: 1,
                            name: 'Option1',
                            systemField: true
                        },
                        {
                            color: 'data-grid-badge-color-6',
                            id: 2,
                            name: 'Option2'
                        }
                    ],
                    required: false,
                    type: 'multi-select',
                    width: 150
                },
                {
                    disableWebhook: true,
                    dropdownConfig: {
                        allowSearch: true,
                        button: {
                            className: 'bg-blue text-white',
                            onClick: function noRefCheck() {
                            },
                            title: '+ Add New'
                        },
                        className: 'shadow-m',
                        data: [],
                        onSelect: function noRefCheck() {
                        },
                        renderer: function noRefCheck() {
                        },
                        searchApiConfig: {
                            dataParser: function noRefCheck() {
                            },
                            getUrl: function noRefCheck() {
                            }
                        },
                        searchableKey: 'name',
                        style: {
                            minWidth: 230
                        },
                        title: 'Select Comment'
                    },
                    formatter: function noRefCheck() {
                    },
                    id: '9',
                    key: 'comments',
                    name: 'Dropdown with API',
                    options: [],
                    type: 'dropdown',
                    width: 200
                },
                {
                    id: '99',
                    key: 'column_9',
                    name: '',
                    options: [
                        {
                            className: ' p-0',
                            icon: DKIcons.ic_delete,
                            onClick: function noRefCheck() {
                            }
                        }
                    ],
                    type: 'button',
                    width: 150
                }
            ]}
            currentPage={2}
            dateFormat="MMM dd, yyyy"
            filterOperator="and"
            needBorder
            needColumnIcons
            needShadow
            needTrailingColumn
            onAllRowSelect={function noRefCheck() {
            }}
            onColumnAdd={function noRefCheck() {
            }}
            onColumnAddClick={function noRefCheck() {
            }}
            onColumnDelete={function noRefCheck() {
            }}
            onColumnShift={function noRefCheck() {
            }}
            onColumnUpdate={function noRefCheck() {
            }}
            onFilter={function noRefCheck() {
            }}
            onNextPageTapped={function noRefCheck() {
            }}
            onPageChange={null}
            onPrevPageTapped={function noRefCheck() {
            }}
            onRowAdd={function noRefCheck() {
            }}
            onRowClick={function noRefCheck() {
            }}
            onRowExpand={function noRefCheck() {
            }}
            onRowOpenClick={function noRefCheck() {
            }}
            onRowSelect={function noRefCheck() {
            }}
            onRowUpdate={function noRefCheck() {
            }}
            onSort={function noRefCheck() {
            }}
            rows={[
                {
                    age: 23,
                    compensation: 1500,
                    dob: new Date('2022-12-09T05:42:59.624Z'),
                    invalidFields: [
                        'user'
                    ],
                    multioption: [
                        0
                    ],
                    profile: 'Developer',
                    rowContextMenu: [
                        {
                            icon: DKIcons.ic_open,
                            onClick: function noRefCheck() {
                            },
                            title: 'Open'
                        },
                        {
                            icon: DKIcons.ic_copy,
                            onClick: function noRefCheck() {
                            },
                            title: 'Copy'
                        }
                    ],
                    selected: false,
                    tax: {
                        id: '0001',
                        name: 'Tax 1'
                    },
                    user: {
                        age: 23,
                        title: 'Invalid Field'
                    }
                },
                {
                    age: 43,
                    allowRowEdit: false,
                    compensation: 500,
                    dob: new Date('2022-12-09T05:42:59.624Z'),
                    profile: 'Manger',
                    rowButtons: [
                        {
                            className: 'bg-chip-blues p-0',
                            icon: DKIcons.ic_open,
                            onClick: function noRefCheck() {
                            },
                            title: 'Open'
                        }
                    ],
                    selected: false,
                    tax: {
                        id: '0002',
                        name: 'Tax 2'
                    },
                    user: {
                        age: 43,
                        title: 'Sam Muller'
                    }
                },
                {
                    age: 23,
                    compensation: 1500,
                    dob: new Date('2022-12-09T05:42:59.624Z'),
                    expandableView: <div
                        className="p-h-xxl bg-gray1 parent-width column align-items-center justify-content-center"
                        style={{height: 100}}><DKLabel className="mt-r fs-m" text="This is the expanded view for row"/>
                    </div>,
                    expanded: true,
                    profile: 'Alex Wheels the dev',
                    selected: false,
                    tax: {
                        id: '0001',
                        name: 'Tax 1'
                    },
                    user: {
                        age: 23,
                        title: 'Alex Wheels'
                    }
                }
            ]}
            searchBarConfig={{
                placeHolder: 'Search User',
                searchText: 'Enter to search'
            }}
            showHeader
            title="Basic Example of DKDataGrid"
            totalPageCount={3}
            updating
            width="100%"
        />
    );
};

export default Test;
