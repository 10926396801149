import React from "react";
import ReactDOM from "react-dom";
import API from "./api";
import Popup from "./common/Popup";
import {
  DKButton,
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  getDataTypeIcon,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  isEmpty,
  showToast,
  TOAST_TYPE,
} from "deskera-ui-library";
import DKFormEditorPers from "./DKFormEditorPers";
import ControlledInput from "./common/ControlledInput";

import { CHAR_CODES } from "../constants/Constant";
import { getCapitalized, getRandomNumber } from "../utility/Utility";

const CUSTOM_INPUT_TYPE = {
  ...INPUT_TYPE, // Spread existing types
  REF: "ref", // Add your custom type
};

export interface IAddColumnPopupProps {
  tableName: string;
  popupId?: string;
  onSave?: (response?: any) => void;
  onClose?: () => void;
  appId: string;
  tableId: string;
  onFormulaEdit?: any;
  columnDList?: any;
}

export interface IAddColumnPopupState {
  formData: any;
  optionFieldText: string;
  submitted: boolean;
  reftabID: string;
  refTabName: string;
  tableName: string;
  needFormulaEditorPopup: boolean;
}

class AddColumnPopup extends React.Component<
  IAddColumnPopupProps,
  IAddColumnPopupState
> {
  constructor(props) {
    super(props);
    console.log("table name", props.tableName);

    this.state = {
      formData: {
        name: "",
        type: INPUT_TYPE.TEXT,
        required: false,
        editable: true, // Placeholder, adjust as necessary
        hidden: false, // Placeholder, adjust as necessary
        systemField: false, // Placeholder, adjust as necessary
        uiVisible: true, // Placeholder, adjust as necessary
        options: [],
        formula: [],
      },
      optionFieldText: "",
      submitted: false,
      reftabID: "",
      refTabName: "",
      tableName: props.tableName,
      needFormulaEditorPopup: false,
    };
  }
  hideFormulaEditorPopup = () => {
    this.setState({
      needFormulaEditorPopup: false,
    });
  };
  getFormulaEditorPopup() {
    return (
      <DKFormEditorPers
        columns={this.props.columnDList}
        formula={this.state.formData.formula}
        onSaveFormula={(formula) => {
          this.state.formData["formula"] = formula;

          this.hideFormulaEditorPopup();
        }}
        onClose={() => this.hideFormulaEditorPopup()}
      />
    );
  }
  onOptionChange = (value) => {
    this.setState({
      optionFieldText: value,
    });
  };
  onOptionKeyPress = (e) => {
    if (e.charCode === CHAR_CODES.ENTER) {
      // on enter pressed
      this.onOptionAdd();
    }
  };
  onOptionAdd = () => {
    if (isEmpty(this.state.optionFieldText)) return;
    let options = [...this.state.formData.options];
    let { optionFieldText } = this.state;
    const newID = parseInt(
      getRandomNumber(1000) + "" + this.state.formData.options.length
    );
    options.push({
      id: newID,
      name: optionFieldText,
      color: `data-grid-badge-color-${Math.round(Math.random() * 8) + 1}`,
    });
    this.onFormValueChange("options", options);
    this.setState({
      optionFieldText: "",
    });
  };
  onFormValueChange = (key, value) => {
    console.log("on form value change ", key, value);
    if (value === CUSTOM_INPUT_TYPE.FORMULA) {
      this.setState({
        needFormulaEditorPopup: true,
      });
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    });
  };
  onSave = async () => {
    this.setState({
      submitted: true,
    });

    // Early return conditions
    if (this.state.formData.name.trim().length === 0) {
      return;
    }

    if (
      this.state.formData.required &&
      (this.state.formData.type === "SELECT" ||
        this.state.formData.type === "MULTI_SELECT") &&
      (!this.state.formData.options || this.state.formData.options.length === 0)
    ) {
      showToast("Please add at least one option", TOAST_TYPE.FAILURE);
      return;
    }

    // Prepare column data with assumed mappings and defaults
    const columnData = {
      name: this.state.formData.name,
      index: 0, // Placeholder, adjust as necessary
      type: this.state.formData.type.toLowerCase(), // Convert type as needed
      options: this.state.formData.options || [],
      required: this.state.formData.required,
      width: this.state.formData.width || 200, // Adjust default width as needed
      editable: this.state.formData.editable, // Placeholder, adjust as necessary
      hidden: this.state.formData.hidden, // Placeholder, adjust as necessary
      systemField: this.state.formData.systemField, // Placeholder, adjust as necessary
      uiVisible: this.state.formData.uiVisible, // Placeholder, adjust as necessary
      refTable: { _id: this.state.reftabID },
      formula: this.state.formData.formula || [],
    };

    // API call to add column
    try {
      const { tableId } = this.props;

      let response;
      console.log("COLUMN DATA: ", columnData);
      response = await API.post(
        `app-builder/table/${tableId}/column`,
        columnData
      );
      console.log("res", response);
      if (response.statusCode >= 200 && response.statusCode <= 400) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const addedColumn = await response.data;
      console.log("Column added successfully:", addedColumn);

      // Call onSave callback with the added column information
      if (this.props.onSave) {
        this.props.onSave({ columnData: addedColumn });
        this.removePopUp();
      }
    } catch (error) {
      console.error("Error adding column:", error);
      showToast("Error adding column", TOAST_TYPE.FAILURE);
    }
  };

  onCancel = () => {
    this.removePopUp();
  };
  removePopUp = () => {
    if (this.props.popupId)
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
    document.getElementById(this.props.popupId)?.remove();
    if (this.props.onClose) this.props.onClose();
  };

  render() {
    const { appId, tableId } = this.props;

    return (
      <Popup>
        {this.getHeader()}
        {this.getForm()}
      </Popup>
    );
  }

  getHeader = () => {
    return (
      <div className="row align-items-center justify-content-between parent-width">
        <div className="row fs-xl fw-h">New Column</div>
        <div className="row-reverse action-btn-wrapper">
          <DKButton
            className="border-m ml-r bg-button text-white"
            title="Save"
            onClick={this.onSave}
          />
          <DKButton
            className="border-m bg-white"
            title="Cancel"
            onClick={this.onCancel}
          />
        </div>
      </div>
    );
  };
  onOptionRemove = (index) => {
    let options = [...this.state.formData.options];
    options.splice(index, 1);
    this.onFormValueChange("options", options);
  };
  getForm = () => {
    const { appId, tableId } = this.props;
    return (
      <>
        <div className="row mt-l">
          <ControlledInput
            required
            invalid={
              this.state.submitted &&
              this.state.formData.name.trim().length === 0
            }
            name="Name"
            type={INPUT_TYPE.TEXT}
            value={this.state.formData.name}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            onChange={(e) => this.onFormValueChange("name", e.target.value)}
          />
        </div>
        <div className="row mt-l">{this.getFieldTypeSelection()}</div>
        {(this.state.formData.type === CUSTOM_INPUT_TYPE.SELECT ||
          this.state.formData.type === CUSTOM_INPUT_TYPE.MULTI_SELECT) && (
          <div className="row mt-l">{this.getAddOptionField()}</div>
        )}
        {this.state.formData.type === CUSTOM_INPUT_TYPE.REF && (
          <div className="row mt-l">
            {/* <DKInput
              value={this.state.refTabName}
              formatter={(obj) => {
                return obj;
              }}
              title="Pick a table"
              type={INPUT_TYPE.DROPDOWN}
              required={true}
              onChange={(value) => {}}
              dropdownConfig={{
                style: {},
                allowSearch: true,
                searchableKey: "name",

                searchApiConfig: {
                  getUrl: (val) => {
                    return `https://api-dev.deskera.xyz/v1/app-builder/table?includeFilters=false&appId=${appId}&q=${encodeURI(
                      val
                    )}`;
                  },
                  dataParser: (response) => {
                    return response.data;
                  },
                  getPayload: () => {
                    return null;
                  },
                },
                data: [],
                renderer: (index, obj) => {
                  console.log("table name: ", this.state.tableName);
                  if (obj.name !== this.state.tableName)
                    return (
                      <DKLabel
                        needIcon
                        text={`${obj.name}<br><span style="font-size: 12px; color:gray; margin-top: 3px;">${obj.nameField}</span>`}
                        // text={`${obj}<br><span style="font-size: 12px; color:gray; margin-top: 3px;">Id: ${obj.id}</span>`}
                      />
                    );
                },

                onSelect: (index, value) => {
                  console.log("value", value);
                  const ReftAselected = value._id;
                  const ReftANameSelected = value.name;
                  this.setState({
                    reftabID: ReftAselected,
                    refTabName: ReftANameSelected,
                  });
                },
              }}
            /> */}
            <DKInput
              value={this.state.refTabName}
              formatter={(obj) => {
                return obj;
              }}
              title="Pick a table"
              type={INPUT_TYPE.DROPDOWN}
              required={true}
              onChange={(value) => {}}
              dropdownConfig={{
                style: {},
                allowSearch: true,
                searchableKey: "name",

                searchApiConfig: {
                  getUrl: (val) => {
                    return `https://api-dev.deskera.xyz/v1/app-builder/table?includeFilters=false&appId=${appId}&q=${encodeURI(
                      val
                    )}`;
                  },
                  dataParser: (response) => {
                    return response.data;
                  },
                  getPayload: () => {
                    return null;
                  },
                },
                data: [],
                renderer: (index, obj) => {
                  console.log("objjjjj", obj);
                  console.log("table name: ", this.state.tableName);
                  if (obj.name !== this.state.tableName)
                    return (
                      <DKLabel
                        needIcon
                        text={`${obj.name}<br><span style="font-size: 12px; color:gray; margin-top: 3px;">${obj.nameField}</span>`}
                        // text={`${obj}<br><span style="font-size: 12px; color:gray; margin-top: 3px;">Id: ${obj.id}</span>`}
                      />
                    );
                },

                onSelect: (index, value) => {
                  console.log("value", value);
                  const ReftAselected = value._id;
                  const ReftANameSelected = value.name;
                  this.setState({
                    reftabID: ReftAselected,
                    refTabName: ReftANameSelected,
                  });
                  console.log("ReftANameSelected", ReftANameSelected);
                },
              }}
            />
          </div>
        )}

        {this.state.formData.type === CUSTOM_INPUT_TYPE.FORMULA &&
          this.state.needFormulaEditorPopup &&
          this.getFormulaEditorPopup()}
        {this.state.formData.options.length > 0 && this.getOptionList()}

        <div className="grid grid-cols-3 gap-auto w-full">
          <div className={`row align-items-center`}>
            <DKCheckMark
              className="mt-l mb-m"
              isSelected={this.state.formData.required}
              onClick={(value) =>
                this.onFormValueChange(
                  "required",
                  !this.state.formData.required
                )
              }
            />
            <DKLabel text="Is mandatory?" className="ml-s mt-s" />
          </div>
          <div className={`row align-items-center`}>
            <DKCheckMark
              className="mt-l mb-m"
              isSelected={this.state.formData.editable}
              onClick={(value) =>
                this.onFormValueChange(
                  "editable",
                  !this.state.formData.editable
                )
              }
            />
            <DKLabel text="Is editable?" className="ml-s mt-s" />
          </div>
          <div className={`row align-items-center`}>
            <DKCheckMark
              className="mt-l mb-m"
              isSelected={this.state.formData.systemField}
              onClick={(value) =>
                this.onFormValueChange(
                  "systemField",
                  !this.state.formData.systemField
                )
              }
            />
            <DKLabel text="Is systemField?" className="ml-s mt-s" />
          </div>
          <div className={`row align-items-center`}>
            <DKCheckMark
              className="mt-l mb-m"
              isSelected={this.state.formData.hidden}
              onClick={(value) =>
                this.onFormValueChange("hidden", !this.state.formData.hidden)
              }
            />
            <DKLabel text="Is hidden?" className="ml-s mt-s" />
          </div>
          <div className={`row align-items-center`}>
            <DKCheckMark
              className="mt-l mb-m"
              isSelected={this.state.formData.uiVisible}
              onClick={(value) =>
                this.onFormValueChange(
                  "uiVisible",
                  !this.state.formData.uiVisible
                )
              }
            />
            <DKLabel text="Is UI Visible?" className="ml-s mt-s" />
          </div>
        </div>
      </>
    );
  };

  getFieldTypeSelection = () => {
    const INPUT_TYPE_ARR = [
      CUSTOM_INPUT_TYPE.TEXT,
      CUSTOM_INPUT_TYPE.NUMBER,
      CUSTOM_INPUT_TYPE.DATE,
      CUSTOM_INPUT_TYPE.SELECT,
      CUSTOM_INPUT_TYPE.MULTI_SELECT,
      CUSTOM_INPUT_TYPE.FORMULA,
      CUSTOM_INPUT_TYPE.REF, // Add your custom input type here
    ];
    const UPDATED_ARR = [...INPUT_TYPE_ARR];
    return (
      <div className="parent-width">
        <DKLabel text={"Select field type"} />
        <div className={`column-type-wrapper`}>
          {UPDATED_ARR.map((item) => {
            return (
              <div
                className={`p-s bg-white mr-r mt-r border-radius-s border-m d-flex align-items-center cursor-hand
                            ${
                              this.state.formData.type === item
                                ? "border-blue text-blue fw-m"
                                : ""
                            }`}
                onClick={() => {
                  this.onFormValueChange("type", item);
                  setTimeout(() => this.onFormValueChange("options", []), 0);
                }}
              >
                <DKIcon
                  src={getDataTypeIcon(item)}
                  className="ic-xs cursor-hand"
                />
                <span className="ml-s">{getCapitalized(item)}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  getAddOptionField = () => {
    return (
      <div className="row align-items-end" style={{ width: "80%" }}>
        <ControlledInput
          required
          name="Options"
          type={INPUT_TYPE.TEXT}
          value={this.state.optionFieldText}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(e) => this.onOptionChange(e.target.value)}
          onKeyPress={(e) => this.onOptionKeyPress(e)}
        />
        <DKButton
          title="Add"
          style={{ marginBottom: "3px" }}
          className="border-m ml-r bg-button text-white"
          onClick={this.onOptionAdd}
        />
      </div>
    );
  };
  getOptionList = () => {
    return (
      <div className="row pt-s flex-wrap">
        {this.state.formData.options.map((option, index) => {
          return (
            <div
              className={`p-s border-radius-s mr-s mt-s d-flex align-items-center ${option.color}`}
              id={`${option.id}`}
            >
              <span>{option.name}</span>
              <DKIcon
                src={DKIcons.ic_close}
                className="ic-s ml-s cursor-hand"
                onClick={() => this.onOptionRemove(index)}
              />
            </div>
          );
        })}
      </div>
    );
  };
}

export const showAddColumnPopup = (
  config: {
    tableName: string;
    appId: string;
    tableId: string;
    columnDList: any;
  },
  onSave?: (response?: any) => void,
  onClose?: () => void
) => {
  const id = `add-new-col-popup-${new Date().getTime()}`;
  // const { appId } = this.props;

  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <AddColumnPopup
      popupId={id}
      tableName={config.tableName}
      onSave={onSave}
      onClose={onClose}
      appId={config.appId}
      tableId={config.tableId}
      columnDList={config.columnDList}
    />,
    document.body.appendChild(div)
  );
};
export default AddColumnPopup;
