import {Component, Fragment} from "react";
import {connect} from "react-redux";
import "./menu.scss";
import {DKSidebar, showToast, TOAST_TYPE,} from "deskera-ui-library";

import {PAGE_ROUTES} from "../../managers/RouteManager";
import {isMobileAppWebView, isViewportLarge,} from "../../utility/GetViewportSize";

import IAM from "../../services/iam";
import SideBarService from "../../services/sidebar";
import UserManager from "../../managers/UserManager";
import AppManager from "../../managers/AppManager";

import Popup from "../../components/common/Popup";
import AddNewTenant from "../common/AddNewTenant";
import Utility from "../../utility/Utility";
import NavigateContext from "../../Helper/NavigationContext";

import ic_dashboard from '../../assets/menu/filled/ic_dashboard.png'
import ic_split from '../../assets/activity/ic-graph.png'

class LeftMenu extends Component {
    static contextType = NavigateContext;

    constructor(props) {
        super(props);

        this.state = {
            isMenuExpanded: SideBarService.isSideBarExpanded,
            showAddTenantPopup: false,
            selectedTenantDetails: {
                tenantName: UserManager.getUserTenantName(),
                tenantId: UserManager.getUserTenantID(),
            },
        };
    }

    componentDidMount() {
        window.addEventListener("onSideBarChange", this.handleSidebarToggle);
        window.addEventListener("resize", this.handleSidebarOnResize);
    }

    componentWillUnmount() {
        window.removeEventListener("onSideBarChange", this.handleSidebarToggle);
        window.removeEventListener("resize", this.handleSidebarOnResize);
    }

    handleSidebarOnResize() {
        SideBarService.setIsSideBarExpanded();
        SideBarService.triggerSidebarChangeEvent();
    }

    handleSidebarToggle = () => {
        this.setState({isMenuExpanded: SideBarService.isSideBarExpanded});
    };

    onSelectTenant = (newSelectedTenantDetails) => {
        if (
            newSelectedTenantDetails.tenantId !==
            this.state.selectedTenantDetails.tenantId
        ) {
            IAM.switchTenant({tenantId: newSelectedTenantDetails.tenantId}).then(
                (res) => {
                    AppManager.reloadApp();
                },
                (err) => {
                }
            );
        } else {
            showToast("Current organisation", TOAST_TYPE.SUCCESS);
        }
    };

    render() {
        return !AppManager.isMobileAppURL() ? this.renderMenu() : null;
    }

    renderMenu() {
        const menuItemList = this.getFilteredMenuItems(this.getMenuItems());
        console.log("menu item list ", menuItemList);
        return (
            <Fragment>
                <div
                    className={`transparent-background parent-height parent-width position-absolute ${
                        this.state.isMenuExpanded
                            ? "display-only-mobile z-index-5"
                            : "display-none z-index-0"
                    }`}
                    onClick={() => SideBarService.toggleSideBar()}
                ></div>
                <DKSidebar
                    className={`z-index-6 ${
                        isMobileAppWebView() ? "mobile-app-theme-color" : ""
                    }`}
                    allowTenantSelection={!isMobileAppWebView()}
                    collapsedWidth={0}
                    expandedWidth={230}
                    menuItemList={menuItemList}
                    tenantList={this.props.tenants}
                    isMenuExpanded={this.state.isMenuExpanded}
                    selectedTenantDetails={this.state.selectedTenantDetails}
                    onAddNewTenantTapped={(data) =>
                        this.setState({showAddTenantPopup: true})
                    }
                    onTenantSelect={(newTenantDetails) =>
                        this.onSelectTenant(newTenantDetails)
                    }
                    onExpandCollapse={() => SideBarService.toggleSideBar()}
                />
                {this.state.showAddTenantPopup && (
                    <Popup popupWindowStyles={{overflow: "visible"}}>
                        <AddNewTenant
                            onClose={() => this.setState({showAddTenantPopup: false})}
                            onSave={(data) => this.setState({showAddTenantPopup: false})}
                        />
                    </Popup>
                )}
            </Fragment>
        );
    }

    onMenuItemClick = (routeKey) => {
        const routePath = PAGE_ROUTES[routeKey];
        const navigate = this.context;
        if (routePath && navigate) {
            navigate(routePath);
            if (!isViewportLarge()) {
                SideBarService.toggleSideBar();
            }
        } else {
            console.error(
                "Route not found or navigate function not available for key:",
                routeKey
            );
        }
        // Additional logic...
    };

    getFilteredMenuItems = (menuItems) => {
        if (Utility.isEmptyObject(menuItems)) return menuItems;

        return menuItems
            .filter((item) => item.visible)
            .map((item) => ({
                ...item,
                subItems: this.getFilteredMenuItems(item.subItems),
            }));
    };

    getMenuItems = () => {
        return [
            {
                name: "Home",
                icon: ic_dashboard, // Add the appropriate icon for Home
                route: PAGE_ROUTES.HOME,
                visible: true,
                onClick: () => this.onMenuItemClick("HOME"),
            },
            {
                name: "Applications",
                icon: ic_dashboard,
                route: PAGE_ROUTES.APPLICATION_LIST,
                visible: true,
                onClick: () => this.onMenuItemClick("APPLICATION_LIST"),
            },
            {
                name: "Deployments",
                icon: ic_split,
                routeKey: "LOGS",
                visible: true,
                onClick: () => this.onMenuItemClick("LOGS"),
            },
        ];
    };
}

const mapStateToProps = (state) => ({
    tenants: state.tenant?.tenants || [],
});

export default connect(mapStateToProps, null)(LeftMenu);
