import {store} from "../redux/store";
import {fetchTenants, fetchUsers} from "../redux/slices/tenantSlice";

export default class InitialApiManager {
    static makeInitialApiCalls(onSuccess) {

        store.dispatch(fetchUsers());


        Promise.all([
            store.dispatch(fetchTenants()),
        ]).then(() => {
            console.log("fetched tenants")
        });
    }

}