import {configureStore} from "@reduxjs/toolkit";
// import tableReducer from "./slices/tableSlice";
// import recordReducer from "./slices/recordSlice";
// import userPrefReducer from "./slices/userPrefSlice";
import tenantReducer from "./slices/tenantSlice";
import applicationReducer from "./slices/applicationSlice";
// import productReducer from "./slices/productSlice";
// import booksReducer from "./slices/booksSlice";
// import reportReducer from "./slices/reportSlice";
// import tableMetaDataReducer from "./slices/tableMetaDataSlice";
// import rolePermissionReducer from "./slices/rolesPermissionSlice";
// import shopReducer from "./slices/shopSlice";
// import priceBookReducer from "./slices/priceBookSlice";
// import currencyReducer from "./slices/currencySlice";
// import productGroupReducer from "./slices/ProductGroupSlice";

export const store = configureStore({
    reducer: {
        applications: applicationReducer,
        // table: tableReducer,
        // records: recordReducer,
        tenant: tenantReducer,
        // userPref: userPrefReducer,
        // products: productReducer,
        // tableMetaData: tableMetaDataReducer,
        // rolesPermission: rolePermissionReducer,
        // books: booksReducer,
        // reports: reportReducer,
        // priceBook: priceBookReducer,
        // shop: shopReducer,
        // currency: currencyReducer,
        // productGroup: productGroupReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
