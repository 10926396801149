import {DEFAULT_TRIAL_PLAN_DAYS} from "../constants/Constant";
import {PRODUCTS} from "../constants/Enum";
import {store} from "../redux/store";
import Utility from "../utility/Utility";

class UserManager {
    static userDetails = null;
    static userPlanDetails = null;

    static getUserDetails = () => {
        return this.userDetails;
    };

    static setUserDetails = (data) => {
        this.userDetails = data;
    };
    static getUserEmail = () => {
        return this.userDetails ? this.userDetails.email : null;
    };
    static getUserName = () => {
        return this.userDetails ? this.userDetails.name : null;
    };
    static getUserPhoneNumber = () => {
        return this.userDetails ? this.userDetails.phone : null;
    };
    static getUserCountry = () => {
        return this.userDetails ? this.userDetails.country : null;
    };
    /**
     * @description - this function returns the currency received token that is from book app while setting the tenant
     * @returns Currency code for the User
     */
    static getUserCurrency = () => {
        return this.userDetails ? this.userDetails.currency : null;
    };
    static getUserID = () => {
        return this.userDetails ? this.userDetails.id : null;
    };
    static getUserIamID = () => this.userDetails.imID;
    static getUserTenantID = () => {
        return this.userDetails ? this.userDetails.tenantID : null;
    };
    static getUserTenantName = () => {
        return this.userDetails?.tenantName || "Company Name";
    };
    static setUserTenantName = (tenantName) => {
        if (Utility.isEmptyObject(tenantName) || Utility.isEmptyObject(this.userDetails)) return;

        this.userDetails.tenantName = tenantName;
    }

    static hasProductAccess(userInfo, products = [PRODUCTS.CRM_PLUS]) {
        return Boolean(
            userInfo?.roleShortInfo?.some((role) =>
                products.includes(role.appName?.toLowerCase())
            )
        );
    }

    static setUserPlanDetails(planDetails) {
        this.userPlanDetails = planDetails;
    }

    static isTrialPlanActive() {
        return this.userPlanDetails?.InTrial && !this.userPlanDetails?.TrialOver;
    }

    static isTrialPlanAvailable() {
        return !this.userPlanDetails?.InTrial && !this.userPlanDetails?.TrialOver;
    }

    static getTrialDays(inTrialMode = false) {
        /* Trial Days for an unsubscribed user */
        let trialDays = this.userPlanDetails?.TrialDays ?? DEFAULT_TRIAL_PLAN_DAYS;

        /* Pending trial days */
        try {
            if (this.userPlanDetails && inTrialMode) {
                const trialEndTime = new Date(this.userPlanDetails.TrialEndDate).getTime();
                const currentTime = new Date().getTime();

                trialDays = Math.ceil((trialEndTime - currentTime) / (1000 * 60 * 60 * 24));
                trialDays = trialDays < 0 ? 0 : trialDays;
            }
        } catch (err) {
            trialDays = 0;
        }

        return trialDays;
    }

    static isPlanActive() {
        return this.userPlanDetails?.Subscribed || this.isTrialPlanActive();
    }

    static getUserPlanName() {
        return this.userPlanDetails?.PlanName;
    }

    static isUserOwner = (userID = null) => {
        if (Utility.isEmptyObject(userID)) {
            userID = this.getUserID();
        }
        const tenantInfo = store.getState()?.books?.tenantsDetails;
        return tenantInfo?.userId == userID;
    }
    static getTaxResidency = () => {
        return this.userDetails?.taxResidency;
    }
    static isComplianceEnabled = () => {
        return !!this.userDetails?.complianceEnabled
    }
}

export default UserManager;
